import Vue from "vue";

export function getCurrentDate() {
    const storedDate = localStorage.getItem('currentDate');
    if (storedDate !== undefined && storedDate !== null) {
        return Vue.prototype.$moment(storedDate);
    }
    return null;
}

export function setCurrentDate( date) {
    localStorage.setItem('currentDate', date);
}

export function removeCurrentDate() {
    localStorage.removeItem('currentDate');
}