import Vue from 'vue';
import linkifyHtml from "linkifyjs/lib/linkify-html";

export default function() {
    Vue.filter('date', (value, format) => {
        return value ? Vue.prototype.$moment(value).format(format) : null;
    });

    Vue.filter('new_line_to_br', (value) => {
        if (!value) {
            return value;
        }
        return value.toString().replace(/(?:\r\n|\r|\n)/g, '<br>');
    });

    Vue.filter('linkify', (value) => {
        return linkifyHtml(value, {
            defaultProtocol: 'https',
            target: {
                url: '_blank'
            },
        });
    });
}