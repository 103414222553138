<template>
  <blank-page-layout>
    <v-container class="pa-0" fluid>
      <v-row no-gutters justify="center" class="h-100">
        <v-col cols="12" md="6" class="position-relative d-none d-md-block">
          <v-img height="100vh" src="/images/splash.png"/>

          <div class="login-links d-flex justify-center align-center w-100">
            <a class="v-btn" :class="$vuetify.rtl ? 'ml-sm-7' : 'mr-sm-7'" target="_blank" :href="appleLink">
              <v-img src="/images/apple.svg"/>
            </a>

            <a class="v-btn" :class="$vuetify.rtl ? 'mr-sm-7' : 'ml-sm-7'" target="_blank" :href="androidLink">
              <v-img src="/images/google.svg"/>
            </a>
          </div>

        </v-col>
        <v-col cols="12" md="6">
          <v-container fluid class="fill-height">
            <v-row no-gutters class="justify-center fill-height">
              <v-col cols="12" sm="11" md="10" class="d-flex justify-end pt-5">
                <v-img max-width="138" height="54" src="/images/login-logo.svg"/>
              </v-col>
              <v-col sm="6" md="8" lg="6" cols="12" class="mt-15 mt-sm-5">
                <v-form :disabled="submitLoading" @submit.prevent="initLogin" class="single-column-form">
                  <v-container>
                    <v-row no-gutters>
                      <v-col cols="6">
                        <div class="text-h2 Text01--text">{{ $t('loginBtnLogin') }}</div>
                      </v-col>
                      <v-col cols="6" class="d-flex justify-end align-center">
                        <language-changer/>
                      </v-col>
                      <v-col class="mt-5" cols="12">
                        <v-text-field
                            v-model="email"
                            :error-messages="emailErrors"
                            :label="$t('registerUserName')"
                            hide-details="auto"
                            type="email"
                            class="mb-8"
                            outlined
                            :class="{ 'input-group--dirty': dirtyEmail }"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                            v-model="password"
                            :error-messages="passwordErrors"
                            :label="$t('loginPassword')"
                            outlined
                            :class="{ 'input-group--dirty': dirtyPwd }"
                            :type="showPassword ? 'text' : 'password'"
                        >
                          <template v-slot:append>
                            <v-icon color="Text03" @click="showPassword = !showPassword">
                              {{ showPassword ? 'mdi-eye' : 'mdi-eye-off' }}
                            </v-icon>
                          </template>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" class="d-flex justify-center mt-6" v-if="showCaptchaWidget">
                        <div ref="captchaElement" class="frc-captcha" :data-sitekey="captchaSiteKey"/>
                      </v-col>

                      <v-col cols="12" class="d-flex justify-space-between align-center">
                        <v-checkbox hide-details v-model="keepMeLoggedIn" class="mt-0 pt-0">
                          <template v-slot:label>
                            <span class="text-body-1 Text01--text">{{ $t('loginCheckbox') }}</span>
                          </template>
                        </v-checkbox>

                        <router-link class="text-decoration-none text-body-2" :to="{name: 'forgot_password'}">
                          {{ $t('loginBtnForgotPassword') }}
                        </router-link>
                      </v-col>

                      <v-col cols="12" class="mt-15 text-center">
                        <v-btn
                            block
                            depressed
                            :loading="submitLoading"
                            :disabled="submitDisabled"
                            type="submit"
                            color="primary"
                        >
                          {{ $t('loginBtnLogin') }}
                        </v-btn>
                      </v-col>

                    </v-row>
                  </v-container>
                </v-form>
              </v-col>
              <v-col cols="12" class="align-center d-flex justify-center mt-15 mt-sm-0">
                <a
                    target="_blank"
                    class="text-decoration-none text-body-2"
                    :class="$vuetify.rtl ? 'ml-3' : 'mr-3'"
                    :href="globalPrivacyPolicyUrl"
                    v-if="Boolean(globalPrivacyPolicyUrl)"
                >
                  {{ $t('privacy_policy_title') }}
                </a>
                <a
                    target="_blank"
                    class="text-decoration-none text-body-2"
                    :class="$vuetify.rtl ? 'm3-3' : 'ml-3-3'"
                    :href="globalTermsAndConditions"
                    v-if="Boolean(globalTermsAndConditions)"
                >
                  {{ $t('termsConditions') }}
                </a>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
    <association-selector
        :open.sync="openAssociationSelector"
        @close="associationSelectorClosed"
        @selectAssociation="initLoginWithAssociation"/>
  </blank-page-layout>
</template>

<script>
import AssociationSelector from "@/components/AssociationSelector";
import validationMixin from "@/mixins/validationMixin";
import {mapActions, mapGetters} from 'vuex';
import {UserGetters, UserActions, AuthStatuses} from '@/store/user';
import {required, email} from "vuelidate/lib/validators";
import {WidgetInstance} from 'friendly-challenge';
import {AppConfig} from "@/helpers/AppConfig";
import i18next from "i18next";
import {RTL_LANGUAGE_CODES} from "@/i18nInit";
import {saveLocale} from "@/helpers/helpers";
import BlankPageLayout from "@/components/BlankPageLayout";
import LanguageChanger from "@/components/LanguageChanger";
import SmartBanner from 'smart-app-banner';
import {LegalActions} from "@/store/legalsDocuments";

export default {
  name: "LoginPage",

  components: {
    BlankPageLayout,
    AssociationSelector,
    LanguageChanger,
  },

  head: {
    meta: [
      {
        name: 'apple-itunes-app',
        content: `app-id=${process.env.VUE_APP_APP_STORE_ID}`
      },
      {
        name: 'google-play-app',
        content: `app-id=${process.env.VUE_APP_GOOGLE_PLAY_APP_ID}`
      },
    ],
  },

  data() {
    return {
      email: null,
      password: null,
      keepMeLoggedIn: false,
      submitLoading: false,
      openAssociationSelector: false,
      selectedAssociationCode: '',
      dirtyEmail: null,
      dirtyPwd: null,
      showCaptchaWidget: false,
      captchaWidget: null,
      captchaSolution: '',
      captchaSiteKey: '',
      submitDisabled: false,
      openAssociationSelectorOnceCaptchaSolved: false,
      showPassword: false,
      globalPrivacyPolicyUrl: null,
      globalTermsAndConditions: null,
      appleLink: process.env.VUE_APP_APP_STORE_URL,
      androidLink: process.env.VUE_APP_GOOGLE_PLAY_APP_LINK
    };
  },
  async mounted() {
    this.initSmartBanner();

    i18next.on('languageChanged', () => {
      this.initSmartBanner();
    });

    await this.initLoadingPageData();

    this.checkPreSelectedAssociation();

    let times = 0;
    const interval = setInterval(() => {
      times += 1;

      if ((this.dirtyEmail && this.dirtyPwd) || times === 20) {
        clearInterval(interval);
      }

      this.dirtyEmail = document.querySelector('input[type="email"]:-webkit-autofill');
      this.dirtyPwd = document.querySelector('input[type="password"]:-webkit-autofill');
    }, 100);

    this.captchaSiteKey = AppConfig.captchaSiteKey;
  },
  computed: {
    ...mapGetters({
      preSelectedAssociationId: `user/${UserGetters.PreSelectedAssociationId}`,
      accountDetails: `user/${UserGetters.AccountDetails}`,
      accountConfiguration: `user/${UserGetters.AccountConfiguration}`
    }),
    emailErrors() {
      return this.getErrorMessagesForFieldWithRules('email', {
        required,
        email: this.$t('invalidEmailAddress')
      });
    },
    passwordErrors() {
      return this.getErrorMessagesForFieldWithRules('password', {
        required,
      });
    },
  },

  mixins: [validationMixin],

  validations: {
    email: {required, email},
    password: {required}
  },

  methods: {
    ...mapActions({
      login: `user/${UserActions.Login}`,
      updatePreSelectedAssociationId: `user/${UserActions.UpdatePreSelectedAssociationId}`,
      fetchLegalItems: `legalsDocuments/${LegalActions.GetLegalLinks}`,
    }),

    initSmartBanner() {
      const smartbanner = document.querySelector('.smartbanner')

      if (smartbanner) {
        smartbanner.remove();
      }

      new SmartBanner({
        daysHidden: 15,
        daysReminder: 90,
        appStoreLanguage: this.$i18n.i18next.language,
        title: 'Stay-Informed-App',
        author: 'Stay Informed GmbH',
        button: this.$t('view'),
        store: {
          ios: this.$t('app_store'),
          android: this.$t('google_play'),
        },
        price: {
          ios: this.$t('get'),
          android: this.$t('get'),
        },
      });
    },

    associationSelectorClosed() {
      this.submitLoading = false;

      if (this.showCaptchaWidget) {
        this.submitDisabled = true;
        this.captchaWidget.reset();
      }
    },

    async initLoadingPageData() {
      await this.fetchLegalItems()
          .then(async ({data}) => {
            const {global_privacy_policy_url, global_terms_and_conditions} = data;

            this.globalPrivacyPolicyUrl = global_privacy_policy_url;
            this.globalTermsAndConditions = global_terms_and_conditions;
          })
          .catch(e => {
            this.$toasted.error(e.message);
          });
    },

    checkPreSelectedAssociation() {
      if (this.preSelectedAssociationId) {
        this.selectedAssociationCode = this.preSelectedAssociationId;
        this.updatePreSelectedAssociationId(null);
      }
    },
    async initLogin() {
      if (!navigator.onLine) {
        this.$toasted.clear();
        return this.$toasted.error(this.$t('loginOfflineMessage'));
      }

      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.submitLoading = true;

      if (this.showCaptchaWidget) {
        this.submitDisabled = true;
        this.captchaWidget.reset();
      }

      this.login({
        email: this.email,
        password: this.password,
        associationCode: this.selectedAssociationCode,
        keepMeLoggedIn: this.keepMeLoggedIn,
        solution: this.captchaSolution,
      }).then((authStatus) => {
        if (authStatus === AuthStatuses.ActivateCaptcha) {
          this.showCaptchaWidget = true;
          this.submitDisabled = true;
          this.submitLoading = false;

          this.$nextTick(() => this.startCaptchaWidget());

          return true;
        }

        if (authStatus === AuthStatuses.SelectAssociation) {
          if (this.showCaptchaWidget) {
            this.openAssociationSelectorOnceCaptchaSolved = true;
          } else {
            this.openAssociationSelector = true;
          }

          this.submitLoading = false;

          return true;
        }
        this.submitLoading = false;
        this.setLanguage(this.accountDetails.app_language);

        if (this.accountConfiguration && Object.prototype.hasOwnProperty.call(this.accountConfiguration, 'timezone')
            && this.accountConfiguration.timezone
        ) {
          this.$moment.tz.setDefault(this.accountConfiguration.timezone);
        }

        let redirectTo = {name: 'news'};
        if (Object.prototype.hasOwnProperty.call(this.$route.query, 'redirectTo')
            && this.$route.query.redirectTo !== '/'
        ) {
          redirectTo = this.$route.query.redirectTo;
        }
        return this.$router.replace(redirectTo);
      }).catch((e) => {
        this.$toasted.error(e.message);
        this.selectedAssociationCode = '';
        this.submitLoading = false;
      });
    },
    initLoginWithAssociation(associationCode) {
      this.selectedAssociationCode = associationCode;

      if (this.showCaptchaWidget) {
        this.captchaWidget.destroy();
        this.captchaWidget = null;
        this.showCaptchaWidget = false;
      }

      return this.initLogin();
    },
    doneCallback(solution) {
      this.captchaSolution = solution;
      this.submitDisabled = false;

      if (this.openAssociationSelectorOnceCaptchaSolved) {
        this.openAssociationSelectorOnceCaptchaSolved = false;
        this.openAssociationSelector = true;
      }
    },
    startCaptchaWidget() {
      if (this.showCaptchaWidget) {
        this.captchaWidget = new WidgetInstance(this.$refs.captchaElement, {
          startMode: "auto",
          doneCallback: this.doneCallback,
          language: i18next.language,
        });
      }
    },
    setLanguage(lang) {
      i18next.changeLanguage(lang).then(() => {
        this.$vuetify.rtl = RTL_LANGUAGE_CODES.includes(lang);
      });

      saveLocale(lang);
      this.$moment.locale(lang);
    }
  },
}
</script>

<style scoped lang="scss">
.login-links {
  position: absolute;
  bottom: 50px;
  left: 50px;
}
</style>
