<template>
  <v-row class="fill-height mt-n15 mt-md-n0">
    <v-col cols="12" class="fill-height">
      <div class="main-content-container fill-height" v-if="!loading && pageType">
        <v-card tile elevation="0" class="news-item-card fill-height">
          <v-card-text class="py-0">
            <hidden-banner @initUnHideNewsItem="initUnHideNewsItem" v-if="isHidden"/>

            <v-toolbar class="news-toolbar-mobile mb-3 d-block d-md-none" flat>
              <v-toolbar-items class="w-100 justify-space-between">
                <v-tooltip content-class="text-caption" bottom color="Text04">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-if="!newsItem.is_hidden"
                        icon
                        color="SystemCTA"
                        v-on:click.prevent
                        @click.stop="initToggleFavourite"
                        :disabled="toggleFavouriteRequested"
                        v-bind="attrs"
                        v-on="on"
                    >
                      <v-icon size="30">{{ isFavourite ? 'mdi-star' : 'mdi-star-outline' }}</v-icon>
                    </v-btn>
                  </template>
                  {{ isFavourite ? $t('removeNewsItemFromFavourites') : $t('addNewsItemToFavourites') }}
                </v-tooltip>

                <v-tooltip content-class="text-caption" bottom color="Text04" v-if="!isHidden && !newsItem.is_pinned">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        color="SystemCTA"
                        v-on:click.prevent
                        @click.stop="initHideNewsItem"
                        v-bind="attrs"
                        v-on="on"
                    >
                      <v-icon size="30">mdi-eye-off-outline</v-icon>
                    </v-btn>
                  </template>
                  {{ $t('hideNewsItem') }}
                </v-tooltip>

                <v-tooltip content-class="text-caption" bottom color="Text04">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        @click="copyContentToClipboard"
                        color="SystemCTA"
                        v-bind="attrs"
                        v-on="on"
                    >
                      <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                  </template>
                  {{ $t('copyNewsItem') }}
                </v-tooltip>

                <PinUnpinButton v-if="!isHidden" :show-tooltip="true" :news-item.sync="newsItem"/>
              </v-toolbar-items>
            </v-toolbar>

            <div @click="displayResponseMembers">
              <news-chips
                  :show-tooltip="true"
                  :news-item="newsItem"
              />
            </div>

            <div class="news-tooltip d-none d-md-block outlined elevation-2 pa-2">
              <v-tooltip
                  content-class="text-caption"
                  right
                  color="Text04"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      v-if="!isHidden"
                      icon
                      color="SystemCTA"
                      v-on:click.prevent
                      @click.stop="initToggleFavourite"
                      :disabled="toggleFavouriteRequested"
                      v-bind="attrs"
                      v-on="on"
                      class="d-block my-2"
                  >
                    <v-icon size="24">{{ isFavourite ? 'mdi-star' : 'mdi-star-outline' }}</v-icon>
                  </v-btn>
                </template>
                {{ isFavourite ? $t('removeNewsItemFromFavourites') : $t('addNewsItemToFavourites') }}
              </v-tooltip>

              <v-tooltip
                  v-if="!isHidden && !newsItem.is_pinned"
                  content-class="text-caption"
                  right
                  color="Text04"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      icon
                      color="SystemCTA"
                      v-on:click.prevent
                      @click.stop="initHideNewsItem"
                      v-bind="attrs"
                      v-on="on"
                      class="d-block my-2"
                  >
                    <v-icon size="24">mdi-eye-off-outline</v-icon>
                  </v-btn>
                </template>
                {{ $t('hideNewsItem') }}
              </v-tooltip>

              <v-tooltip
                  content-class="text-caption"
                  right
                  color="Text04"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      icon
                      @click="copyContentToClipboard"
                      color="SystemCTA"
                      v-bind="attrs"
                      v-on="on"
                      class="d-block my-2"
                  >
                    <v-icon size="24">mdi-content-copy</v-icon>
                  </v-btn>
                </template>
                {{ $t('copyNewsItem') }}
              </v-tooltip>

              <div class="mt-2 mb-1">
                <PinUnpinButton
                    v-if="!isHidden"
                    class="d-block"
                    :show-tooltip="true"
                    icon-size="25"
                    tooltip-position="right"
                    :news-item.sync="newsItem"
                />
              </div>
            </div>

            <v-row no-gutters>
              <v-col cols="12">
                <v-list-item class="px-0" three-line>
                  <v-list-item-content class="pt-4">

                    <v-list-item-title class="text-subtitle-1" :class="{'direction-rtl': isRTL}">
                      <div class="text-body-2 Text02--text" v-text="date"/>
                      <div v-if="showPostedBy" class="text-body-2 font-weight-medium Text02--text"
                           v-text="newsItem.poster"/>
                    </v-list-item-title>

                    <div v-if="newsItem.groups.length" class="text-body-2 Text02--text my-4 d-none">
                      {{ newsItem.groups.map(group => group.name).join(" • ") }}
                    </div>

                    <MoreGroups class="w-100" :groups="newsItem.groups"/>

                    <v-list-item-title class="text-subtitle-1 Text01--text" :class="{'direction-rtl': isRTL}">
                      {{ newsItem.title }}
                    </v-list-item-title>

                    <deadline-alert class="mt-3" :newsItem="newsItem"/>

                    <limit-reached-alert v-if="isLimitReached" class="mt-3"/>

                    <div v-if="newsItem.in_app_translator_is_active" class="my-2">
                      <translation-btn @ready="requestTranslatedItem()"/>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="12" class="mt-0 py-0 response-given-to-text" v-if="pageType === 'responseReceived'">
                <v-row no-gutters>
                  <v-col
                      :class="{'d-none': hasResponse(response)}"
                      cols="12"
                      v-for="(response, index) in newsItem.responses"
                      :key="index"
                  >
                    <span
                        class="news-detail-text fs-14"
                        v-if="response.member_ids.length > 0"
                        v-html="$t(getResponseKey, {children: responseChildrenNames(response), response_date: $options.filters.date(response.date_answered, $t('normalDateFormat')), response_time: $options.filters.date(response.date_answered, $t('normalTimeFormat'))})"
                    />
                    <span
                        class="text-body-1 fs-14"
                        v-else
                        v-html="$t('newsResponseTxtReceived', {response_date: $options.filters.date(response.date_answered, $t('normalDateFormat')), response_time: $options.filters.date(response.date_answered, $t('normalTimeFormat'))})"
                    />
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" class="pt-1">
                <div class="Text01--text text-body-1" :class="{'direction-rtl': isRTL}">
                  <p :inner-html.prop="newsItem.content | linkify | new_line_to_br" id="news-body-content"/>
                </div>
              </v-col>

              <v-col v-if="newsItem.videoUrl" cols="12">
                <video-player
                    :videoThumbnail="newsItem.videoThumbnail"
                    :videoUrl="newsItem.videoUrl"
                    :videoType="newsItem.videoType"
                />
              </v-col>

              <v-col cols="12" v-if="newsItem.attachments.length">
                <v-list>
                  <v-list-item
                      class="px-md-1 px-0"
                      @click="initFetchAttachment(attachment)"
                      v-for="attachment in newsItem.attachments"
                      :key="attachment.id"
                  >
                    <v-list-item-icon :class="$vuetify.rtl ? 'ml-4' : 'mr-4'">
                      <v-progress-circular
                          v-if="requestedAttachmentId === attachment.id"
                          indeterminate
                          color="primary"
                          size="34"
                      />
                      <v-icon v-else size="34">
                        {{`$${attachment.type}`}}
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="text-subtitle-2 SystemCTA--text">
                        {{ attachment.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>

              <v-footer
                  v-if="pageType === 'responseReceived' && !isDeadlinePassed"
                  height="80"
                  class="justify-center"
                  color="SystemWhite"
                  style="bottom: 60px"
                  fixed
                  app
              >
                <v-btn
                    :to="{name: answersRouteName, params: {id: newsItem._id}}"
                    exact
                    :max-width="454"
                    class="w-100"
                    depressed
                    :disabled="submitLoading"
                    color="primary"
                    outlined
                >
                  <div class="text-button">
                    {{ newsItem.responses.length > 1 ? $t('seeAnswers') : $t('seeAnswer') }}
                  </div>
                </v-btn>
              </v-footer>

              <v-col cols="12" class="pt-2" v-if="pageType === 'responseReceived'">
                <v-row
                    :class="{'d-none': hasResponse(response)}"
                    no-gutters
                    v-for="(response, index) in newsItem.responses"
                    :key="index"
                    class="mb-5"
                >
                  <v-col cols="12">
                    <component
                        v-bind:is="optionsComponent"
                        :news-item="newsItem"
                        :value="getResponseValueForOptionComponent(response)"
                        :response="response"
                        :readonly="true"
                    />
                  </v-col>
                  <v-col cols="12" v-if="response.comment">
                    <div class="news-detail-text fs-16 fw-700">
                      {{ $t('newsResponseComments') }}
                    </div>
                    <div class="news-detail-text fs-15 op-0_7 pt-2">{{ response.comment }}</div>
                  </v-col>
                  <v-col cols="12 pt-5" v-if="newsItem.responses[index + 1]">
                    <v-divider class="response-divider"/>
                  </v-col>
                </v-row>
                <v-row no-gutters v-if="isDeadlinePassed && newsItem.responses.length === 0" class="mb-5">
                  <v-col cols="12">
                    <component
                        v-bind:is="optionsComponent"
                        :value="getEmptyResponseValueForOptionComponent()"
                        :news-item="newsItem"
                        :readonly="true"
                    />
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" class="pt-0" v-if="pageType === 'responseRequired'">
                <v-form @submit.prevent="initSubmitResponse">
                  <v-row no-gutters>
                    <v-col cols="12">
                      <component
                          v-bind:is="optionsComponent"
                          :news-item="newsItem"
                          v-model="optionsValues"
                          :validation-result="$v.optionsValues"
                      />
                    </v-col>

                    <v-col cols="12" v-if="isOneChildList" class="mb-5">
                      <p class="text-subtitle-1 Text01--text">
                        {{ isAppTypeCare() ? $t('responseWillBeAssignedResident') : $t('responseWillBeAssignedChild') }}
                      </p>

                      <v-list-item class="px-0" v-for="member in members" :key="member.id">
                        <v-list-item-avatar>
                          <avatar
                              :size="40"
                              :initials="member.initials"
                              :src="member.avatar_field"
                              color="white"
                              backgroundColor="var(--v-IconsBlue-base)"
                          />
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title class="text-body-1 Text01--text">
                            {{ member.name }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>

                    </v-col>

                    <v-col cols="12" v-if="isChildrenPickerRequired && !isLimitReached">
                      <children-picker
                          v-model="pickedChildren"
                          :children="members"
                          :answered-by-others-ids="newsItem.answeredMemberOtherIds"
                          :answered-ids="newsItem.answeredMemberIds"
                          :target-groups="newsItem.groups"
                          :validation-result="$v.pickedChildren"
                      />
                    </v-col>

                    <v-col cols="12" v-if="newsItem.show_comment && !isLimitReached" class="my-3">
                      <p class="text-subtitle-1 Text01--text">{{ $t('pleaseAddComment') }}</p>

                      <v-textarea
                          v-model="comment"
                          :error-messages="commentErrors"
                          outlined
                          auto-grow
                          counter
                          :label="$t('newsResponseComments')"
                      >
                        <template v-slot:counter="{ props: { value } }">
                          <div class="Text03--text text-caption font-weight-medium">
                            {{ value }}/300
                          </div>
                        </template>
                      </v-textarea>
                    </v-col>

                    <v-col cols="12" v-if="newsItem.show_signature && !isLimitReached" class="mb-5">
                      <signature v-model="signature" :validation-result="$v.signature"/>
                    </v-col>

                    <v-footer
                        min-height="80"
                        class="justify-center flex-column"
                        color="SystemWhite"
                        style="bottom: 60px"
                        fixed
                        app
                    >
                      <v-btn
                          v-if="!isLimitReached"
                          :max-width="454"
                          class="w-100"
                          depressed
                          :loading="submitLoading"
                          type="submit"
                          color="primary"
                      >
                        <div class="text-button SystemWhite--text">
                          {{ $t('addResponse') }}
                        </div>
                      </v-btn>

                      <v-btn
                          v-if="newsItem.responses.length"
                          :to="{name: answersRouteName, params: {id: newsItem._id}}"
                          exact
                          :max-width="454"
                          class="w-100 mt-3"
                          depressed
                          :disabled="submitLoading"
                          color="primary"
                          outlined
                      >
                        <div class="text-button">
                          {{ newsItem.responses.length > 1 ? $t('seeAnswers') : $t('seeAnswer') }}
                        </div>
                      </v-btn>
                    </v-footer>

                  </v-row>
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>

      <SkeletonLoader v-else/>

      <AttachmentPreviewModal
          v-if="attachmentDialog"
          v-model="attachmentDialog"
          :title="attachmentDialogTitle"
          :attachment="attachmentData"
      />

      <confirm-dialog
          :max-width="544"
          :loading="submitLoading"
          v-model="showDialog"
          :title="$t('newsLeaveTitle')"
          :label="$t('newsLeaveLabel')"
          apply-button-color="primary"
          :apply-button-label="$t('addResponse')"
          :cancel-button-label="$t('leave')"
          @apply="addResponse"
          @close="leave"
      >
        <v-checkbox class="mt-0" hide-details="auto" v-model="checkbox">
          <template v-slot:label>
            <span class="text-body-2 Text01--text">
              {{ $t('dontShowMessage') }}
            </span>
          </template>
        </v-checkbox>
      </confirm-dialog>

      <response-members-dialog
        v-model="responseMembersDialog"
        :news-item="newsItem"
        :cancel-button-label="$t('okay').toUpperCase()"
        :title="$t('responses')"
        :max-width="600"
      />

    </v-col>
  </v-row>

</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {NewsActions} from '@/store/news';
import {UserActions, UserGetters} from '@/store/user';
import VideoPlayer from '@/components/pages/NewsItemPage/components/VideoPlayer';
import HiddenBanner from '@/components/pages/NewsItemPage/components/HiddenBanner';
import AttachmentPreviewModal from "@/components/AttachmentPreviewModal";
import ResponseMembersDialog from "@/components/pages/NewsItemPage/components/ResponseMembersDialog";
import TextareaOptions from "@/components/pages/NewsItemPage/components/TextareaOptions";
import MoreGroups from "@/components/pages/NewsItemPage/components/MoreGroups";
import CheckboxOptions from "@/components/pages/NewsItemPage/components/CheckboxOptions";
import RadioButtonsOptions from "@/components/pages/NewsItemPage/components/RadioButtonsOptions";
import Signature from "@/components/pages/NewsItemPage/components/Signature";
import ChildrenPicker from "@/components/pages/NewsItemPage/components/ChildrenPicker";
import NewsChips from "@/components/pages/NewsItemPage/components/NewsChips";
import DeadlineAlert from "@/components/pages/NewsItemPage/components/DeadlineAlert";
import LimitReachedAlert from "@/components/pages/NewsItemPage/components/LimitReachedAlert";
import {ResponseTypes} from "@/store/news";
import {availableChildren, availableChildrenCount, prettyChildrenNames} from "@/helpers/childrenConditions";
import validationMixin from "@/mixins/validationMixin";
import {required, maxLength} from "vuelidate/lib/validators";
import ConfirmationModal from "@/components/ConfirmationModal";
import ConfirmDialog from "@/components/ConfirmDialog";
import PinUnpinButton from "@/components/pages/NewsListPage/components/PinUnpinButton";
import TranslationBtn from "@/components/TranslationBtn";
import {isInAppTranslationRequestedLangRTL} from "@/helpers/helpers";
import appTypeMixin from "@/mixins/appTypeMixin";
import {Avatar} from "vue-avatar";

export const PageTypes = {
  Article: 'article',
  ResponseRequired: 'responseRequired',
  ResponseReceived: 'responseReceived',
}

export default {
  components: {
    TranslationBtn,
    VideoPlayer,
    AttachmentPreviewModal,
    ResponseMembersDialog,
    TextareaOptions,
    CheckboxOptions,
    RadioButtonsOptions,
    NewsChips,
    Signature,
    ChildrenPicker,
    ConfirmationModal,
    ConfirmDialog,
    PinUnpinButton,
    MoreGroups,
    Avatar,
    DeadlineAlert,
    LimitReachedAlert,
    HiddenBanner,
  },

  data() {
    return {
      submitLoading: false,
      loading: true,
      newsItem: {
        response_type: null
      },
      requestedAttachmentId: null,
      attachmentDialog: false,
      attachmentDialogTitle: null,
      attachmentData: null,
      signature: null,
      optionsValues: {},
      comment: null,
      pickedChildren: [],
      pageType: null,
      openPageTypeSelector: false,
      isRTL: false,
      toggleFavouriteRequested: false,
      to: null,
      showDialog: false,
      checkbox: false,
      responseMembersDialog: false
    }
  },

  beforeRouteLeave(to, from, next) {
    if (this.to || to.name.includes('answer')) {
      next();
    }

    if (this.pageType === 'responseRequired' && !this.isLimitReached) {
      if (JSON.parse(sessionStorage.getItem(this.newsItem._id))) {
        next();
      } else {
        this.to = to;
        this.showDialog = true;
      }
    } else {
      next();
    }
  },

  async mounted() {
    if (this.isAuthenticated) {
      await this.initLoadingPageData();
    }
  },

  mixins: [validationMixin, appTypeMixin],

  validations() {
    let rules = {
      optionsValues: {},
    };

    switch (this.newsItem.response_type) {
      case ResponseTypes.Text:
        this.newsItem.options.map(option => option.id).forEach((optionId) => {
          rules.optionsValues[optionId] = {
            required,
            maxLengthValue: maxLength(300)
          };
        });
        break;
      case ResponseTypes.Single:
      case ResponseTypes.Multi:
        rules.optionsValues = {required};
        break;
    }

    if (this.isChildrenPickerRequired) {
      rules.pickedChildren = {required};
    }

    if (this.newsItem.show_signature) {
      rules.signature = {required};
    }

    if (this.newsItem.show_comment) {
      rules.comment = this.commentValidationRules;
    }

    return rules;
  },

  computed: {
    ...mapGetters({
      members: `user/${UserGetters.Members}`,
      isAuthenticated: `user/${UserGetters.IsAuthenticated}`,
    }),

    commentErrors() {
      return this.getErrorMessagesForFieldWithRules('comment', this.commentValidationRules);
    },

    commentValidationRules() {
        return {maxLengthValue: maxLength(300)};
    },

    answersRouteName() {
      const {is_pinned, responses} = this.newsItem;

      if (is_pinned) {
        return responses.length > 1 ? 'news.pinned.answers' : 'news.pinned.answer';
      }

      return responses.length > 1 ? 'news.answers' : 'news.answer';
    },

    isLimitReached() {
      const {options} = this.newsItem;

      if (this.pageType === PageTypes.Article) {
        return false;
      }

      let filteredOptions = options.filter((option) => {
        const {limited, choices_done, max_choices} = option;

        return limited && (choices_done === max_choices);
      });

      return filteredOptions.length === options.length;
    },

    optionsComponent() {
      switch (this.newsItem.response_type) {
        case ResponseTypes.Text:
          return TextareaOptions;
        case ResponseTypes.Multi:
          return CheckboxOptions;
        case ResponseTypes.Single:
          return RadioButtonsOptions;
        default:
          throw new Error(this.$t('invalidResponseType'));
      }
    },

    date() {
      return this.newsItem ? this.$moment(this.newsItem.date).format(this.$t('newsHeaderDateTime')) : null;
    },

    isOneChildList() {
      return this.membersList.length === 1 && this.members.length === 1;
    },

    isChildrenPickerRequired() {
      if (this.newsItem.groups && this.newsItem.groups.length === 0) {
        return false;
      }
      return this.membersList.length > 0 && this.members.length > 1;
    },

    getResponseKey() {
      if (this.membersList.length >= 1 && this.newsItem.show_signature) {
        return 'newsResponseTxtMembersReceivedWithSignature';
      } else if (this.membersList.length >= 1 && !this.newsItem.show_signature) {
        return 'newsResponseTxtMembersReceived';
      } else if (this.membersList.length == 0 && this.newsItem.show_signature) {
        return 'newsResponseTxtReceivedWithSignature';
      }

      return 'newsResponseTxtReceived'
    },

    isDeadlinePassed() {
      return this.newsItem.deadline && this.$moment(this.newsItem.deadline_date).isBefore(this.$moment(), 'day');
    },

    membersList() {
      return availableChildren(this.members, this.newsItem.groups);
    },

    itemPoster() {
      return '<strong>' + this.newsItem.poster + '</strong>';
    },

    showPostedBy() {
      return (this.newsItem.poster && this.newsItem.poster.length > 0);
    },

    isUnHideMenuItemShown() {
      return this.newsItem.is_hidden;
    },

    isHideMenuItemShown() {
      return !this.newsItem.is_hidden && !this.newsItem.is_pinned;
    },

    isHidden: {
      get() {
        return this.newsItem.is_hidden;
      },
      set(value) {
        this.newsItem.is_hidden = value;
      }
    },

    isFavourite: {
      get() {
        return this.newsItem.favourite;
      },
      set(value) {
        this.newsItem.favourite = value;
      }
    }
  },
  methods: {
    ...mapActions({
      fetchNewsItem: `news/${NewsActions.FetchNewsItem}`,
      fetchAttachment: `news/${NewsActions.FetchAttachment}`,
      fetchMembers: `user/${UserActions.FetchMembers}`,
      sendResponse: `news/${NewsActions.SendResponse}`,
      toggleFavourite: `news/${NewsActions.ToggleFavourite}`,
      hideNewsItem: `news/${NewsActions.HideNewsItem}`,
      unHideNewsItem: `news/${NewsActions.UnHideNewsItem}`,
    }),

    addResponse() {
      this.showDialog = false;
      this.to = null;

      if (this.checkbox) {
        sessionStorage.setItem(this.newsItem._id, this.checkbox);
      }
    },

    leave() {
      this.showDialog = false;
      this.$router.push(this.to);

      if (this.checkbox) {
        sessionStorage.setItem(this.newsItem._id, this.checkbox);
      }
    },

    hasResponse(response) {
      return typeof response !== "undefined";
    },

    async initHideNewsItem() {
      this.hideNewsItem({
        newsId: this.newsItem._id,
        isRead: this.isRead
      }).then(async () => {
        this.$toasted.info(this.$t('newsItemHidden'));
        await this.initLoadingPageData();
      }).catch((e) => {
        this.$toasted.error(e.message);
      });
    },

    async initUnHideNewsItem() {
      this.unHideNewsItem(this.newsItem._id)
          .then(async () => {
            this.$toasted.info(this.$t('newsItemUnhidden'));
            await this.initLoadingPageData();
          }).catch((e) => {
        this.$toasted.error(e.message);
      });
    },

    async initToggleFavourite() {
      this.toggleFavouriteRequested = true;
      this.toggleFavourite(this.newsItem._id).then(() => {
        this.isFavourite = !this.isFavourite;
        this.toggleFavouriteRequested = false;

        if (this.isFavourite) {
          this.$toasted.info(this.$t('newsItemAddedToFavourites'));
        } else {
          this.$toasted.info(this.$t('newsItemRemovedFromFavourites'));
        }
      }).catch((e) => {
        this.$toasted.error(e.message);
        this.toggleFavouriteRequested = false;
      });
    },

    async initLoadingPageData() {
      this.loading = true;
      this.pageType = null;

      this.fetchNewsItem({id: this.$route.params.id, translationLang: this.$route.params.translationLang})
          .then(async data => {
            this.newsItem = data;

            if (this.newsItem.type === 'response') {
              await this.fetchMembers();
            }

            this.parsePageType();

            switch (this.newsItem.response_type) {
              case ResponseTypes.Text:
                this.optionsValues = {};
                this.newsItem.options.map(option => option.id).forEach((optionId) => {
                  this.optionsValues[optionId] = null;
                });
                break;
              case ResponseTypes.Multi:
                this.optionsValues = [];
                break;
              case ResponseTypes.Single:
                this.optionsValues = null;
                break;
            }

            this.comment = null;
            this.pickedChildren = [];
            this.signature = null;

            this.isRTL = isInAppTranslationRequestedLangRTL(this.$route.params.translationLang);
          })
          .catch(e => {
            this.$toasted.error(e.message);
          });

      this.loading = false;

      this.$nextTick(() => {
        this.$v.$reset()
      })
    },

    parsePageType() {
      if (this.newsItem.answered && this.newsItem.receiver_type === 'news_direct') {
        this.pageType = PageTypes.ResponseReceived;
        return;
      }

      if (this.newsItem.type === PageTypes.Article) {
        this.pageType = PageTypes.Article;
        return;
      }

      if (this.isDeadlinePassed) {
        this.pageType = PageTypes.ResponseReceived;
        return;
      }

      if (this.newsItem.answered && this.membersList.length < 2) {
        this.pageType = PageTypes.ResponseReceived;
        return;
      }

      const answeredCount = this.newsItem.answeredMemberIds.length;

      if (answeredCount === 0) {
        this.pageType = PageTypes.ResponseRequired;
        return;
      }

      const availableCount = availableChildrenCount(this.membersList, this.newsItem.groups);

      if (answeredCount >= availableCount) {
        this.pageType = PageTypes.ResponseReceived;
      } else {
        this.pageType = PageTypes.ResponseRequired;
      }
    },

    async initFetchAttachment({id, news_id, name}) {
      this.requestedAttachmentId = id;
      this.fetchAttachment({newsId: news_id, attachmentId: id}).then((data) => {
        if (id === this.requestedAttachmentId) {
          this.attachmentData = data;
          this.attachmentDialogTitle = name;
          this.attachmentDialog = true;
          this.requestedAttachmentId = null;
        }
      }).catch(() => {
        this.requestedAttachmentId = null;
      });
    },

    initSubmitResponse() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.submitLoading = true;

      if (this.membersList.length === 1) {
        this.pickedChildren = [this.membersList[0].id];
      }

      this.sendResponse({
        newsItem: this.newsItem,
        formData: {
          optionsValues: this.optionsValues,
          comment: this.comment,
          membersIds: this.pickedChildren,
          signature: this.signature
        }
      }).then(async () => {
        if (this.newsItem.receiver_type === 'news_direct') {
          this.$toasted.success(this.$t('newsResponseSuccessfullyPosted'));
        } else {
          if (this.isAppTypeCare()) {
            if (this.this.pickedChildren.length > 1) {
              this.$toasted.success(this.$t('successfullyPostedResponseForResidents'));
            } else {
              this.$toasted.success(this.$t('successfullyPostedResponseForResident'));
            }
          } else {
            if (this.pickedChildren.length > 1) {
              this.$toasted.success(this.$t('successfullyPostedResponseForChildren'));
            } else {
              this.$toasted.success(this.$t('successfullyPostedResponseForChild'));
            }
          }
        }

        this.submitLoading = false;
        await this.initLoadingPageData();
      }).catch((e) => {
        this.$toasted.error(e.message);
        this.submitLoading = false;
      });
    },

    responseChildrenNames(response) {
      return prettyChildrenNames(this.membersList, response.member_ids);
    },

    getResponseValueForOptionComponent(response) {
      let textValues = {};
      switch (this.newsItem.response_type) {
        case ResponseTypes.Text:
          response.feedback_answers.forEach((responseAnswer) => {
            textValues[responseAnswer.option_id] = responseAnswer.fbtext;
          })
          return textValues;
        case ResponseTypes.Single:
          return response.options_chosen.length > 0 ? response.options_chosen[0] : null;
        default:
          return response.options_chosen;
      }
    },

    getEmptyResponseValueForOptionComponent() {
      switch (this.newsItem.response_type) {
        case ResponseTypes.Text:
          return {};
        case ResponseTypes.Single:
          return null
        default:
          return [];
      }
    },

    copyContentToClipboard() {
      this.$toasted.info(this.$t('newsCopyText'));

      const richTextDiv = document.getElementById("news-body-content");

      const clipboardItem = new window.ClipboardItem({
        "text/plain": new Blob(
            [richTextDiv.innerText],
            {type: "text/plain"}
        ),
        "text/html": new Blob(
            [richTextDiv.outerHTML],
            {type: "text/html"}
        ),
      });

      navigator.clipboard.write([clipboardItem]);
    },

    async requestTranslatedItem() {
      await this.initLoadingPageData();
    },

    displayResponseMembers() {
      return this.responseMembersDialog = (
          this.membersList.length > 0 &&
          this.newsItem.type === 'response' &&
          this.newsItem.receiver_type === 'news'
      );
    }
  }
}
</script>

<style lang="scss" scoped>
.news-tooltip {
  position: fixed;
  top: 10%;
  margin-left: -80px;
}
</style>
