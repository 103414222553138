<template>
  <v-row class="fill-height">
    <v-col class="fill-height">
      <SkeletonLoader v-if="loading"/>

      <div class="main-content-container fill-height" v-else>
        <v-card tile elevation="0" class="news-item-card fill-height">
          <v-card-text class="py-0">
            <v-row no-gutters>

              <v-col cols="12" class="pt-2">
                <v-row no-gutters v-for="(response, index) in newsItem.responses" :key="index" class="mb-5">
                  <v-col cols="12">
                    <component
                        v-bind:is="optionsComponent"
                        :news-item="newsItem"
                        :value="getResponseValueForOptionComponent(response)"
                        :response="response"
                        :readonly="true"
                    />
                  </v-col>
                  <v-col cols="12" v-if="response.comment">
                    <div class="text-subtitle-1 Text01--text">
                      {{ $t('comment') }}
                    </div>
                    <div class="text-body-1 Text01--text mt-3">{{ response.comment }}</div>
                  </v-col>
                  <v-col cols="12" v-if="newsItem.responses[index + 1]">
                    <v-divider class="my-5"/>
                  </v-col>
                </v-row>

                <v-row no-gutters v-if="isDeadlinePassed && newsItem.responses.length === 0" class="mb-5">
                  <v-col cols="12">
                    <component
                        v-bind:is="optionsComponent"
                        :value="getEmptyResponseValueForOptionComponent()"
                        :news-item="newsItem"
                        :readonly="true"
                    />
                  </v-col>
                </v-row>
              </v-col>

            </v-row>
          </v-card-text>
        </v-card>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {NewsActions} from "@/store/news";
import {UserActions} from "@/store/user";
import {ResponseTypes} from "@/store/news";
import {UserGetters} from "@/store/user";
import {isInAppTranslationRequestedLangRTL} from "@/helpers/helpers";
import {availableChildren} from "@/helpers/childrenConditions";
import TextareaOptions from "@/components/pages/NewsItemPage/components/TextareaOptions";
import CheckboxOptions from "@/components/pages/NewsItemPage/components/CheckboxOptions";
import RadioButtonsOptions from "@/components/pages/NewsItemPage/components/RadioButtonsOptions";

export default {
  name: "AnswersPage",

  data() {
    return {
      loading: true,
      newsItem: {
        responses: []
      },
      optionsValues: {},
      isRTL: false,
    }
  },

  async mounted() {
    await this.initLoadingPageData();
  },

  computed: {
    ...mapGetters({
      members: `user/${UserGetters.Members}`,
    }),

    optionsComponent() {
      switch (this.newsItem.response_type) {
        case ResponseTypes.Text:
          return TextareaOptions;
        case ResponseTypes.Multi:
          return CheckboxOptions;
        case ResponseTypes.Single:
          return RadioButtonsOptions;
        default:
          throw new Error(this.$t('invalidResponseType'));
      }
    },

    membersList() {
      return availableChildren(this.members, this.newsItem.groups);
    },

    isDeadlinePassed() {
      return this.newsItem.deadline && this.$moment(this.newsItem.deadline_date).isBefore(this.$moment(), 'day');
    },
  },

  methods: {
    ...mapActions({
      fetchNewsItem: `news/${NewsActions.FetchNewsItem}`,
      fetchMembers: `user/${UserActions.FetchMembers}`,
    }),

    getResponseValueForOptionComponent(response) {
      let textValues = {};
      switch (this.newsItem.response_type) {
        case ResponseTypes.Text:
          response.feedback_answers.forEach((responseAnswer) => {
            textValues[responseAnswer.option_id] = responseAnswer.fbtext;
          })
          return textValues;
        case ResponseTypes.Single:
          return response.options_chosen.length > 0 ? response.options_chosen[0] : null;
        default:
          return response.options_chosen;
      }
    },

    getEmptyResponseValueForOptionComponent() {
      switch (this.newsItem.response_type) {
        case ResponseTypes.Text:
          return {};
        case ResponseTypes.Single:
          return null
        default:
          return [];
      }
    },

    async initLoadingPageData() {
      this.loading = true;

      this.fetchNewsItem({id: this.$route.params.id})
          .then(async data => {
            this.newsItem = data;

            if (this.newsItem.type === 'response') {
              await this.fetchMembers();
            }

            switch (this.newsItem.response_type) {
              case ResponseTypes.Text:
                this.optionsValues = {};
                this.newsItem.options.map(option => option.id).forEach((optionId) => {
                  this.optionsValues[optionId] = null;
                });
                break;
              case ResponseTypes.Multi:
                this.optionsValues = [];
                break;
              case ResponseTypes.Single:
                this.optionsValues = null;
                break;
            }

            this.isRTL = isInAppTranslationRequestedLangRTL(this.$route.params.translationLang);
          })
          .catch(e => {
            this.$toasted.error(e.message);
          });

      this.loading = false;
    },
  }
}
</script>