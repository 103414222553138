<template>
  <v-container fluid class="d-flex flex-column pt-0">
    <v-card flat>
      <v-card-title class="justify-center mb-md-2 gap-4">
        <v-btn text icon color="SystemCTA" @click="prevWeek">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <span class="text-h6 font-weight-medium">{{ weekdaysTitle }}</span>
        <v-btn text icon color="SystemCTA" @click="nextWeek">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="d-flex flex-column pa-0">
        <v-row no-gutters>
          <v-col
            v-for="day in weekdays"
            :key="day"
            class="text-center font-weight-medium px-0"
            :class="day === 'Sat' || day === 'Sun' ? 'Text03--text' : 'Text01--text'"
          >
            {{ day }}
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-2 mt-md-6 mt-lg-6">
          <v-col
            v-for="(date, index) in dates"
            :key="index"
            class="position-relative text-center font-weight-medium px-0"
            :class="{
                  'current-date': isCurrentDate(date),
                  'highlight-border': isBorderOfWeek(date),
                  'highlight-middle': isWithinWeek(date),
                  }"
          >
            <div
              class="date-item position-relative rounded-circle d-flex align-center justify-center mx-auto"
              :class="{'Text03--text': isWeekend(date)}"
            >
              {{ date.date() }}
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { getCurrentDate } from "@/helpers/localStorageUtils";

export default {
  name: "WeeklyCalendar",

  data() {
    return {
      weekdays: [ this.$t('mon'), this.$t('tue'), this.$t('wed'), this.$t('thu'), this.$t('fri'), this.$t('sat'), this.$t('sun') ],
      currentDate: this.$moment().startOf('day'),
      dates: [],
    };
  },

  created() {
    const storedCurrentDate = getCurrentDate();
    if (storedCurrentDate) {
      this.currentDate = storedCurrentDate;
    }

    this.generateDates();
  },

  computed: {
    weekdaysTitle() {
      const [ startOfWeek, endOfWeek ] = this.getWeekdayRange();
      let formatKey;

      if (!startOfWeek.isSame(endOfWeek, 'year')) { // Check for different years
        formatKey = 'differentYearTitle';
      } else if (!startOfWeek.isSame(endOfWeek, 'month')) { // Check for different months
        formatKey = 'differentMonthTitle';
      } else { // Default to the same month
        formatKey = 'sameMonthTitle';
      }
      const formatString = this.$t(`${ formatKey }`);

      return `${ startOfWeek.format(formatString.split('-')[0].trim()) } - ${ endOfWeek.format(formatString.split('-')[1].trim()) }`;
    }
  },

  methods: {
    isStartOfWeek( date ) {
      return date.isoWeekday() === 1;
    },

    isEndOfWeek( date ) {
      return date.isoWeekday() === 5
    },

    isWithinWeek( date ) {
      const weekday = date.isoWeekday();
      return weekday > 1 && weekday < 5;
    },

    isBorderOfWeek( date ) {
      return !this.isCurrentDate(date) && (this.isStartOfWeek(date) || this.isEndOfWeek(date));
    },

    isCurrentDate( date ) {
      return !this.isWeekend(date) && date.isSame(this.$moment(), 'day');
    },

    isWeekend( date ) {
      const day = date.isoWeekday();
      return day === 6 || day === 7;
    },

    nextWeek() {
      this.currentDate.add(1, 'week');
      this.generateDates();
    },

    prevWeek() {
      this.currentDate.subtract(1, 'week');
      this.generateDates();
    },

    generateDates() {
      this.dates = [];
      const startOfWeek = this.currentDate.clone().startOf('isoWeek');
      for (let i = 0; i < 7; i++) {
        this.dates.push(startOfWeek.clone().add(i, 'days'));
      }
    },

    getWeekdayRange() {
      const startOfWeek = this.dates.find(date => this.isStartOfWeek(date));
      const endOfWeek = this.dates.slice().reverse().find(date => this.isEndOfWeek(date));
      return [ startOfWeek, endOfWeek ];
    },
  },
  watch: {
    dates: {
      handler() {
        const [ startDate, endDate ] = this.getWeekdayRange();
        const formattedStartDate = this.$moment(startDate).locale('en').format(this.$moment.HTML5_FMT.DATE);
        const formattedEndDate = this.$moment(endDate).locale('en').format(this.$moment.HTML5_FMT.DATE);

        this.$emit('datesUpdated', { startDate: formattedStartDate, endDate: formattedEndDate });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.date-item {
  color: var(--v-Text01-base);
  width: 40px;
  height: 40px;
  z-index: 1;
}

.current-date .date-item {
  border: 1px solid var(--v-SystemCTA-base);
  background-color: var(--v-SystemBG-base);
}

.highlight-border .date-item {
  background: var(--v-SystemCTA-base);
  color: var(--v-SystemWhite-base);
}

.highlight-middle {
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: -50%; // Stretch to the end of the previous date
    right: -50%; // Stretch to the start of the next date
    height: 40px;
    background-color: var(--v-SystemBG-base);
    transform: translateY(-50%);
    z-index: 0;
  }
}
</style>

