<template>
  <div class="text-center">
    <v-menu tile offset-y min-width="200">
      <template v-slot:activator="{ on, attrs }">
        <a
            class="language-changer-activator text-decoration-non"
            v-bind="attrs"
            v-on="on"
        >
          <span class="text-capitalize text-body-2">{{ getLanguageTitle(selectedLanguage) }}</span>
          <v-icon>mdi-menu-down</v-icon>
        </a>
      </template>
      <v-list tile class="py-0">
        <template v-for="(lang, index) in languages">
          <v-list-item
              @click="changeLanguage(lang)"
              class="cursor-pointer"
              :key="index">
            <v-list-item-title class="text-capitalize text-body-2">
              <span class="flag">{{ languageFlagEmojis[lang] }}</span>
              {{ getLanguageTitle(lang) }}
              <v-icon color="primary" :class="$vuetify.rtl ? 'float-left' : 'float-right'"
                      v-if="lang === selectedLanguage">mdi-check
              </v-icon>
            </v-list-item-title>
          </v-list-item>

          <v-divider
              v-if="index < languages.length - 1"
              :key="`lang-divider-${index}`"
          />
        </template>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import i18next from 'i18next';
import {saveLocale, setVeeValidateTranslations} from "@/helpers/helpers";
import {AVAILABLE_LANGUAGES, LANGUAGE_FLAG_EMOJIS, LANGUAGE_TITLES, RTL_LANGUAGE_CODES} from "@/i18nInit";
import {mapActions} from "vuex";
import {UserActions} from "@/store/user";

export default {
  data: () => ({
    selectedLanguage: null,
  }),

  mounted() {
    this.selectedLanguage = this.$i18n.i18next.language;
  },

  computed: {
    languages() {
      return AVAILABLE_LANGUAGES;
    },
    languageFlagEmojis() {
      return LANGUAGE_FLAG_EMOJIS;
    },
  },
  methods: {
    ...mapActions({
      updateAccountDetails: `user/${UserActions.UpdateAccountDetails}`,
    }),

    getLanguageTitle(lang) {
      if (Object.prototype.hasOwnProperty.call(LANGUAGE_TITLES, lang)) {
        return this.$t(LANGUAGE_TITLES[lang]);
      }

      return lang;
    },
    changeLanguage(lang) {
      i18next.changeLanguage(lang).then(() => {
        this.$vuetify.rtl = RTL_LANGUAGE_CODES.includes(lang);
      });

      setVeeValidateTranslations(i18next.language);

      saveLocale(lang);

      this.$moment.locale(lang);

      this.selectedLanguage = lang;

      this.updateAccountDetails({appLanguage: lang});
    }
  }
}
</script>
