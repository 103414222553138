<template>
  <div>
    <v-sheet v-if="!onlyModal"
             class="mb-6 d-flex align-center pa-3 flex-row"
             :class="{'flex-column': info}"
             min-height="56"
             outlined>
      <div class="d-flex justify-space-between align-center w-100">
        <span class="text-body-1 primary--text">{{ title }}</span>

        <div class="text-body-1 Text03--text mb-0 d-flex">
          <div class="d-sm-none-fix">
            <template
                v-if="appLangSelector && !translationLangSelector || (!appLangSelector && !translationLangSelector)">
              <span class="text-capitalize">{{ getLanguageTitle(selectedLanguage) }}</span>
            </template>
            <template v-if="!appLangSelector && translationLangSelector">
              <span class="text-capitalize">{{ getLanguageTitle(translationLang) }}</span>
            </template>
          </div>

          <template v-if="languageListInModal && !languageListInMenu">
            <v-icon :class="$vuetify.rtl ? 'mr-4' : 'ml-4'" color="primary" @click="dialog = true">mdi-chevron-right
            </v-icon>
          </template>
          <template v-if="!languageListInModal && languageListInMenu || (!languageListInModal && !languageListInMenu)">
            <v-menu>
              <template v-slot:activator="{ on, attrs }">
                <v-icon :class="$vuetify.rtl ? 'mr-4' : 'ml-4'" color="primary" v-bind="attrs" v-on="on">mdi-chevron-right</v-icon>
              </template>

              <v-list class="py-0">
                <v-list-item
                    @click="changeLanguage(lang)"
                    class="cursor-pointer"
                    v-for="(lang, index) in languages"
                    :key="index">
                  <v-list-item-title class="text-capitalize">{{ getLanguageTitle(lang) }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </div>
      </div>

      <div class="mt-5" v-if="info">
        {{ info }}
      </div>
    </v-sheet>

    <v-dialog
        v-model="dialog"
        @click:outside="cancel"
        scrollable
        max-width="600"
        overlay-color="#061F4B"
        overlay-opacity="0.8"
    >
      <v-card tile>
        <v-toolbar flat color="transparent">
          <v-toolbar-title class="text-subtitle-1 Text01--text">
            {{ $t('translationLanguageTitle') }}
          </v-toolbar-title>
          <v-spacer/>
          <v-btn :class="$vuetify.rtl ? 'ml-0' : 'mr-0'" icon color="Text03" @click="cancel">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider/>

        <v-card-text :class="$vuetify.rtl ? 'pl-1' : 'pr-1'">
          <p class="pt-3 mb-1 text-subtitle-2 Text01--text">{{ $t('newsTranslationTitle') }}</p>
          <p class="mb-3 text-body-2 Text02--text">{{ $t('newsTranslationInfo') }}</p>

          <v-list style="max-height: 500px" class="overflow-y-auto py-0">
            <v-radio-group :error-messages="translationErrors" hide-details="auto" class="mt-0"
                           v-model="chooseTranslationLang">
              <v-list-item class="cursor-pointer"
                           :class="$vuetify.rtl ? 'pr-0 pl-2' : 'pl-0 pr-2'"
                           v-for="(lang, index) in languages"
                           :key="index"
                           @click="selectTranslationLang(lang)">
                <v-list-item-content>
                  <v-list-item-title class="text-capitalize" :class="{'text-right': $vuetify.rtl}">
                    <span class="flag" :class="$vuetify.rtl ? 'ml-2' : 'mr-2'">{{ languageFlagEmojis[lang] }}</span>
                    {{ getLanguageTitle(lang) }}
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-radio :value="lang"/>
                </v-list-item-action>
              </v-list-item>
            </v-radio-group>
          </v-list>
        </v-card-text>

        <v-divider/>

        <v-card-actions class="justify-end px-5 py-4">
          <v-btn
              :min-width="106"
              color="primary"
              outlined
              @click="cancel"
          >
            {{ $t('cancel') }}
          </v-btn>

          <v-btn
              :min-width="106"
              color="primary"
              depressed
              @click="initSubmit"
              :class="$vuetify.rtl ? 'mr-4' : 'ml-4'"
          >
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import i18next from 'i18next';
import {saveLocale} from "@/helpers/helpers";
import {
  AVAILABLE_IN_APP_TRANSLATION_LANGUAGES,
  AVAILABLE_LANGUAGES,
  LANGUAGE_FLAG_EMOJIS,
  LANGUAGE_TITLES
} from "@/i18nInit";
import {InAppTranslationLangKey} from "@/constants";
import validationMixin from "@/mixins/validationMixin";
import {required} from "vuelidate/lib/validators";

export default {
  props: {
    title: {
      type: String,
    },
    info: {
      type: String,
    },
    appLangSelector: {
      type: Boolean,
      default: false,
    },
    translationLangSelector: {
      type: Boolean,
      default: false,
    },
    languageListInModal: {
      type: Boolean,
      default: false,
    },
    languageListInMenu: {
      type: Boolean,
      default: false,
    },
    onlyModal: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      selectedLanguage: null,
      dialog: false,
      translationLang: null,
      chooseTranslationLang: null,
    }
  },

  mounted() {
    this.selectedLanguage = this.$i18n.i18next.language;

    const inAppTranslationLang = window.localStorage.getItem(InAppTranslationLangKey) || null;

    this.translationLang = inAppTranslationLang;
    this.chooseTranslationLang = inAppTranslationLang;

    this.dialog = this.onlyModal;
  },

  computed: {
    languages() {
      return this.translationLangSelector ? AVAILABLE_IN_APP_TRANSLATION_LANGUAGES : AVAILABLE_LANGUAGES;
    },

    languageFlagEmojis() {
      return LANGUAGE_FLAG_EMOJIS;
    },

    translationErrors() {
      return this.getErrorMessagesForFieldWithRules('chooseTranslationLang', {
        required,
      });
    },
  },

  mixins: [validationMixin],

  validations: {
    chooseTranslationLang: {required}
  },

  methods: {
    initSubmit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      this.dialog = false;
      this.saveTranslationLang();
    },

    getLanguageTitle(lang) {
      if (Object.prototype.hasOwnProperty.call(LANGUAGE_TITLES, lang)) {
        return this.$t(LANGUAGE_TITLES[lang]);
      }

      return lang;
    },

    changeLanguage(lang) {
      i18next.changeLanguage(lang);
      saveLocale(lang);
      this.$moment.locale(lang);
      this.selectedLanguage = lang;
    },

    selectTranslationLang(lang) {
      this.chooseTranslationLang = lang;
    },

    saveTranslationLang() {
      this.translationLang = this.chooseTranslationLang;

      window.localStorage.setItem(InAppTranslationLangKey, this.translationLang);

      this.$emit('languageSelected', true);
    },

    cancel() {
      this.dialog = false;

      this.chooseTranslationLang = this.translationLang;
    }
  }
}
</script>

<style lang="scss" scoped>
.v-input {
  ::v-deep .v-input__control {
    .v-input__slot {
      order: 2;
    }

    .v-messages {
      order: 1;
    }
  }
}
</style>