<template>
  <v-alert
      v-if="newsItem.deadline"
      rounded="0"
      :color="color"
      outlined
      :type="type"
      icon="mdi-alert-circle"
      class="mb-0"
  >
    <span class="text-body-2 Text01--text" v-html="$t(getDeadlineKey, {deadline: deadlineDate})"/>
  </v-alert>
</template>

<script>

export default {
  name: "DeadlineAlert",

  computed: {
    getDeadlineKey() {
      return this.$moment(this.newsItem.deadline_date).isBefore(this.$moment(), 'day') ? 'newsDeadlinePassed' : 'newsDeadline';
    },

    deadlineDate() {
      return this.newsItem ? this.$moment(this.newsItem.deadline_date).format(this.$t('normalDateFormat')) : null;
    },

    color() {
      return this.getDeadlineKey === 'newsDeadlinePassed' ? 'error' : 'orange';
    },

    type() {
      return this.getDeadlineKey === 'newsDeadlinePassed' ? 'error' : 'warning';
    },
  },

  props: {
    newsItem: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
.v-alert {
  ::v-deep {
    .v-icon {
      align-self: center;
    }
  }
}
</style>