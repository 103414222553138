<template>
  <v-app dark="dark" class="info-app" :class="{mobile: $vuetify.breakpoint.smAndDown}">
    <v-navigation-drawer
        color="primary"
        dark
        v-if="isAuthenticated"
        v-model="drawer"
        fixed
        temporary
        width="363"
        :right="rightNavigationDrawer"
    >
      <v-list class="pa-0 pt-10 px-10">
          <v-list-item class="pb-2 pa-0">
            <v-list-item-avatar size="100" color="SystemBG" class="ma-0">
              <img v-if="associationLogo" :src="'data:image/jpg;base64,' + associationLogo">
              <v-icon color="IconsBlue" size="50" v-else>mdi-city</v-icon>
            </v-list-item-avatar>
          </v-list-item>

        <div class="mx-n4">
          <v-list-group
              v-if="associations.length > 1"
              no-action
              class="navigation-menu"
              active-class="primary SystemWhite--text"
          >
            <template v-slot:activator>
              <v-list-item-title class="text-subtitle-1 text-wrap">
                {{ associationName }}
              </v-list-item-title>
            </template>
            <template v-slot:appendIcon>
              <v-badge
                  :value="hasUnreadNotifications > 0"
                  overlap
                  bordered
                  class="notification-badge"
                  color="AlertRed"
                  offset-x="10"
                  offset-y="10"
              >
                <v-avatar color="SystemWhite" size="30">
                  <v-icon color="SystemCTA">mdi-chevron-up</v-icon>
                </v-avatar>
              </v-badge>
            </template>

            <div class="py-6">
              <v-divider class="absolute-divider"></v-divider>
            </div>

            <template v-for="{id, logo, name, unread} in associations">
              <v-list-item
                  v-if="currentAssociationId !== id"
                  class="px-8"
                  :key="id"
                  @click="showConfirmation(id)"
              >
                <v-list-item-avatar>
                  <v-avatar color="IconsBlue" size="40">
                    <img :alt="name" v-if="logo" :src="'data:image/jpg;base64,' + logo">
                    <v-icon color="SystemWhite" v-else>mdi-city</v-icon>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-title class="text-wrap text-subtitle-1 SystemWhite--text">
                  {{ name }}
                </v-list-item-title>
                <v-icon v-if="unread > 0" class="ml-auto" color="AlertRed" size="12">mdi-circle</v-icon>
              </v-list-item>
            </template>
          </v-list-group>

          <v-list-item v-else>
              <v-list-item-title class="text-subtitle-1 text-wrap">
                {{ associationName }}
              </v-list-item-title>
          </v-list-item>
        </div>
      </v-list>


      <confirmation-modal
          :max-width="544"
          v-model="associationChangeConfirmation"
          :title="$t('switch_association_confirm')"
          :label="$t('switch_association_info')"
          :loading="associationsLoading"
          :apply-button-label="$t('switch')"
          apply-button-color="primary"
          @apply="associationsChange(selectedAssociation)"
      />

      <v-divider class="mt-5 mb-5"/>

      <v-list nav dense class="navigation-menu px-10">
        <v-list-item-group>
          <v-list-item :to="{name: 'news'}" class="py-2" @click="drawer = false">
            <v-list-item-icon class="mr-5">
              <v-icon>mdi-view-day</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('newsSideMenu') }}</v-list-item-title>
            <v-badge class="custom-badge" tile inline :value="showUnreadNewsCount">
              <template v-slot:badge>
                <span class="text-subtitle-2">{{ unreadNewsCount }}</span>
              </template>
            </v-badge>
          </v-list-item>

          <v-list-item v-if="isNoticeboardActive" :to="{name: 'noticeboard'}" class="py-2" @click="drawer = false">
            <v-list-item-icon class="mr-4">
              <v-icon size="27" class="ml-1">$noticeboard</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('noticeboard_title') }}</v-list-item-title>
          </v-list-item>

          <v-list-item :to="{name: 'events'}" class="py-2" @click="drawer = false">
            <v-list-item-icon class="mr-5">
              <v-icon>mdi-calendar</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('eventsSideMenu') }}</v-list-item-title>
          </v-list-item>

          <v-list-item :to="{name: 'chat'}" class="py-2" v-if="isMessengerActive" @click="drawer = false">
            <v-list-item-icon class="mr-5">
              <v-icon>mdi-forum</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('messengerSideMenu') }}</v-list-item-title>
            <v-badge
                class="custom-badge"
                tile
                inline
                :content="unreadChatsCount"
                :value="isUnreadChatsCounterShouldBeShown">
              <template v-slot:badge>
                <span class="text-subtitle-2">{{ unreadChatsCount }}</span>
              </template>
            </v-badge>
          </v-list-item>

          <v-list-item :to="{name: 'contacts'}" class="py-2" @click="drawer = false">
            <v-list-item-icon class="mr-5">
              <v-icon>mdi-account-box</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('contactsSideMenu') }}</v-list-item-title>
          </v-list-item>

          <v-list-item :to="{name: 'members'}" class="py-2" v-if="!isAppTypeTeams() && isMembersActive" @click="drawer = false">
            <v-list-item-icon class="mr-5">
              <v-icon>$human_greeting</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <template v-if="isAppTypeCare()">{{ $t('residentsSideMenu') }}</template>
              <template v-else>{{ $t('childrenSideMenu') }}</template>
            </v-list-item-title>
          </v-list-item>

          <v-list-item v-if="showFoodPlan" :to="{name: 'food_plans'}" class="py-2" @click="drawer = false">
            <v-list-item-icon class="mr-5">
              <v-icon>$fast_food_fill</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('foodPlanSideMenu') }}</v-list-item-title>
          </v-list-item>

          <v-list-item v-if="showTimetable" :to="{name: 'timetables'}" class="py-2" @click="drawer = false">
            <v-list-item-icon class="mr-5">
              <v-icon>mdi-clipboard-text</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('timetableSideMenu') }}</v-list-item-title>
          </v-list-item>

          <v-list-item v-if="showHourPlan" :to="{name: 'hour_plans'}" class="py-2" @click="drawer = false">
            <v-list-item-icon class="mr-5">
              <v-icon>$pending_actions</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('hourPlanSideMenu') }}</v-list-item-title>
          </v-list-item>

          <v-list-item
              @click="drawer = false"
              class="py-2 external-link"
              href="https://stayinformed.zammad.com/help/de-de/31-eltern-web-app"
              target="_blank"
          >
            <v-list-item-icon class="mr-5">
              <v-icon>mdi-help-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('help') }}</v-list-item-title>
          </v-list-item>

        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar height="66" app color="SystemCTA" dark v-if="!hideHeaderFooter">
      <template v-if="isAuthenticated">
        <router-link
            class="text-decoration-none"
            :class="$vuetify.rtl ? 'ml-3' : 'mr-3'"
            v-if="backTo"
            :to="{name:backTo}"
        >
          <v-icon>{{$vuetify.rtl ? 'mdi-arrow-right' : 'mdi-arrow-left'}}</v-icon>
        </router-link>
        <v-app-bar-nav-icon v-else @click="drawer = !drawer"/>
      </template>

      <v-toolbar-title :class="{'ml-0 ml-sm-16': !isAuthenticated}">{{ $t(title) }}</v-toolbar-title>

      <v-spacer/>

      <template v-if="showAdditionalActions">
        <v-text-field
            v-if="isSearchShown"
            :value="query"
            @input="initUpdateQuery"
            :placeholder="$t('actionSearch')"
            hide-details
            clearable
        />

        <v-btn class="mx-1" icon @click="toggleSearch">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>

        <template v-if="topBarComponent">
          <component :class="$vuetify.rtl ? 'ml-3' : 'mr-3'" v-bind:is="topBarComponent"/>
        </template>

      </template>

      <v-menu tile offset-y v-if="isAuthenticated">
        <template v-slot:activator="{ on, attrs }">
          <v-avatar size="50" v-bind="attrs" v-on="on">
            <avatar-component
                :avatar="avatar"
                :name="{firstName, lastName}"
                :size="48"
                :font-size="25"
                color="#3ba8ff"
            />
          </v-avatar>
        </template>
        <v-list class="py-0">
          <v-list-item :to="{name: 'profile_settings'}">
            <v-list-item-icon :class="$vuetify.rtl ? 'ml-3' : 'mr-3'">
              <v-icon size="25" color="SystemCTA">mdi-account-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="text-body-2">{{ $t('accountSettings') }}</v-list-item-title>
          </v-list-item>
          <v-divider/>
          <v-list-item @click="initLogoutConfirm">
            <v-list-item-icon :class="$vuetify.rtl ? 'ml-3' : 'mr-3'">
              <v-icon size="25" color="SystemCTA">mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="text-body-2">{{ $t('logout') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

    </v-app-bar>

    <v-main>
      <v-container class="fill-height" :fluid="hideHeaderFooter"
                   :class="[chatClass, hideHeaderFooter ? 'pa-0' : '']">
        <transition name="route-fade" mode="out-in">
          <router-view class="view"/>
        </transition>
      </v-container>
    </v-main>

    <v-footer v-if="!hideHeaderFooter" height="60" color="GreyFooter">
      <span :class="$vuetify.rtl ? 'ml-2' : 'mr-2'" class="text-body-1">© {{ new Date().getFullYear() }}.</span>

      <a :href="impressumLink" target="_blank" class="text-body-1 text-decoration-none">
        Stay Informed GmbH
      </a>
    </v-footer>

    <confirmation-modal
        :max-width="544"
        v-model="logoutConfirmationDialog"
        :title="$t('logoutTitle')"
        :label="$t('logoutSubtitle')"
        apply-button-color="primary"
        @apply="initLogout"
    />

    <v-overlay :value="overlay">
      <v-progress-circular
          indeterminate
          size="64"
      />
    </v-overlay>

  </v-app>
</template>

<script>

import {mapActions, mapGetters} from 'vuex';
import {UserGetters, UserActions, AppType} from '@/store/user';
import _ from 'lodash';
import {GlobalActions, GlobalGetters} from "@/store/global";
import {getInitialLocale, setVeeValidateTranslations} from "./helpers/helpers";
import ConfirmationModal from "@/components/ConfirmationModal";
import i18next from 'i18next';
import {AssociationsActions} from "@/store/association";
import AvatarComponent from "@/components/pages/AccountSettingsPage/components/AvatarComponent";
import {RTL_LANGUAGE_CODES} from "@/i18nInit";
import appTypeMixin from "@/mixins/appTypeMixin";
import {NewsGetters} from "@/store/news";

export default {
  mixins: [appTypeMixin],

  name: 'App',

  components: {
    AvatarComponent,
    ConfirmationModal,
  },

  data() {
    return {
      overlay: false,
      drawer: false,
      loading: true,
      initUpdateQuery: () => {
      },
      isSearchShown: false,
      logoutConfirmationDialog: false,
      associationsLoading: false,
      association: null,
      associationChangeConfirmation: false,
      selectedAssociation: null,
      associationsList: [],
      rightNavigationDrawer: false,
      impressumLink: process.env.VUE_APP_IMPRESSUM,
    }
  },

  async created() {
    i18next.changeLanguage(getInitialLocale()).then(() => {
      this.$vuetify.rtl = RTL_LANGUAGE_CODES.includes(i18next.language);
    });

    setVeeValidateTranslations(i18next.language);

    this.$moment.locale(i18next.language);
    await this.restoreDataFromLS();

    if (this.isAuthenticated) {
      await this.getAssociationsList();
    }

    this.loading = false;
    this.initUpdateQuery = _.debounce((e) => {
      this.updateQuery(e);
    }, 500);

    if (!this.areDetailsFetched) {
      this.fetchAccountDetails().then(async ({avatarUrl}) => {
        if (avatarUrl) {
          await this.fetchAvatar();
        }
      });
    }

    if (this.accountConfiguration && Object.prototype.hasOwnProperty.call(this.accountConfiguration, 'timezone')
        && this.accountConfiguration.timezone
    ) {
      this.$moment.tz.setDefault(this.accountConfiguration.timezone);
    }

    this.rightNavigationDrawer = RTL_LANGUAGE_CODES.includes(i18next.language);

    i18next.on('languageChanged', (lang) => {
      this.rightNavigationDrawer = RTL_LANGUAGE_CODES.includes(lang);
    });
  },
  computed: {
    ...mapGetters({
      isAuthenticated: `user/${UserGetters.IsAuthenticated}`,
      associationName: `user/${UserGetters.AssociationName}`,
      associationLogo: `user/${UserGetters.AssociationLogo}`,
      accountDetails: `user/${UserGetters.AccountDetails}`,
      avatar: `user/${UserGetters.Avatar}`,
      query: `global/${GlobalGetters.Query}`,
      appType: `user/${UserGetters.AppType}`,
      associationId: `user/${UserGetters.AssociationId}`,
      accountConfiguration: `user/${UserGetters.AccountConfiguration}`,
      unreadChatsCount: `global/${GlobalGetters.UnreadChatsCount}`,
      chats: `chat_app/chat/chats`,
      unreadNewsCount: `news/${NewsGetters.UnreadNewsCount}`,
      associationsFromUser: `user/${UserGetters.UserActiveAssociations}`,
      currentAssociationId: `user/${UserGetters.AssociationId}`,
    }),
    associations() {
      if (this.associationsList && this.associationsList.length > 0) {
        return this.associationsList;
      }
      return this.associationsFromUser;
    },
    showUnreadNewsCount() {
      return (this.unreadNewsCount && this.unreadNewsCount !== null && this.unreadNewsCount > 0);
    },
    dynamicUnreadChatsCount() {
      return this.chats.filter((chat) => chat.unread_messages_count > 0).length;
    },
    areDetailsFetched() {
      return this.accountDetails || !this.isAuthenticated;
    },
    firstName() {
      return this.accountDetails?.first_name;
    },
    lastName() {
      return this.accountDetails?.last_name;
    },
    title() {
      if (Object.prototype.hasOwnProperty.call(this.$route.meta, 'title')) {
        return this.$route.meta.title;
      }
      return '';
    },
    hideHeaderFooter() {
      if (Object.prototype.hasOwnProperty.call(this.$route.meta, 'hideHeaderFooter')) {
        return this.$route.meta.hideHeaderFooter;
      }
      return false;
    },
    showAdditionalActions() {
      if (Object.prototype.hasOwnProperty.call(this.$route.meta, 'showAdditionalActions')) {
        return (this.$route.meta.showAdditionalActions);
      }
      return false;
    },
    backTo() {
      if (Object.prototype.hasOwnProperty.call(this.$route.meta, 'backTo')) {
        return (this.$route.meta.backTo);
      }
      return false;
    },
    topBarComponent() {
      if (Object.prototype.hasOwnProperty.call(this.$route.meta, 'topBarComponent')) {
        return (this.$route.meta.topBarComponent);
      }
      return false;
    },
    showHourPlan() {
      return this.appType === AppType.SchuleHP;
    },
    showFoodPlan() {
      if (!this.accountConfiguration) {
        return false;
      }
      return this.accountConfiguration.showFoodPlan;
    },
    showTimetable() {
      if (!this.accountConfiguration) {
        return false;
      }
      return this.accountConfiguration.showTimetable;
    },
    isMessengerActive() {
      if (!this.accountConfiguration) {
        return false;
      }
      return this.accountConfiguration.groupChatActive || this.accountConfiguration.individualChatActive;
    },
    isMembersActive() {
      if (!this.accountConfiguration) {
        return false;
      }
      return this.accountConfiguration.showMembers;
    },
    isNoticeboardActive() {
      if (!this.accountConfiguration) {
        return false;
      }
      return this.accountConfiguration.isNoticeboardActive || this.accountConfiguration.isNoticeboardActive;
    },
    chatClass() {
      const search = ['start', 'chat'];

      let isChat = false;

      search.map((item) => {
        if (this.$route.name && this.$route.name.includes(item)) {
          isChat = true;
        }
      });

      return isChat ? 'chat-main-content' : ''
    },
    isUnreadChatsCounterShouldBeShown() {
      return this.isMessengerActive && this.unreadChatsCount > 0;
    },
    hasUnreadNotifications() {
      return this.associationsList.some(association => association.unread > 0);
    },
  },
  watch: {
    dynamicUnreadChatsCount() {
      this.updateUnreadChatsCount(this.dynamicUnreadChatsCount);
    },
    '$route'({name}) {
      this.$nextTick(function () {
        const html = document.querySelector('html');
        if (name !== 'login') {
          html.classList.remove("smartbanner-show");
        } else {
          html.classList.add("smartbanner-show");
        }
      })
    }
  },
  methods: {
    ...mapActions({
      logout: `user/${UserActions.Logout}`,
      restoreDataFromLS: `user/${UserActions.RestoreDataFromLS}`,
      updateQuery: `global/${GlobalActions.UpdateQuery}`,
      changeAssociation: `association/${AssociationsActions.ChangeAssociation}`,
      getListOfAssociations: `association/${AssociationsActions.GetListOfAssociations}`,
      fetchAccountDetails: `user/${UserActions.FetchAccountDetails}`,
      fetchAvatar: `user/${UserActions.FetchAvatar}`,
      updateUnreadChatsCount: `global/${GlobalActions.UpdateUnreadChatsCount}`,
    }),
    initLogout() {
      this.logout()
          .then(() => {
            this.logoutConfirmationDialog = false;
            return this.$router.replace({name: 'login'});
          })
    },
    initLogoutConfirm() {
      this.logoutConfirmationDialog = true;
    },
    toggleSearch() {
      this.isSearchShown = !this.isSearchShown;
    },
    showConfirmation(id) {
      this.selectedAssociation = id;
      this.associationChangeConfirmation = true;
    },

    associationsChange(id) {
      this.associationsLoading = true;
      this.overlay = true;

      this.changeAssociation(id)
          .then(() => {
            if (this.$route.name === 'profile_settings') {
              this.$router.push('news');

              this.associationsLoading = false;
              this.overlay = false;
            } else {
              this.$router.go(0);
            }
          })
          .catch((e) => {
            this.associationsLoading = false;
            this.overlay = false;
            this.$toasted.error(e.message);
          });
    },

    getAssociationsList() {
      this.getListOfAssociations()
          .then((data) => {
            this.associationsList = data.associations;
          })
          .catch((e) => {
            this.$toasted.error(e.message);
          });
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .notification-badge {
    .v-badge__badge {
      height: 12px !important;
      min-width: 12px !important;

      &:after {
        border-color: var(--v-SystemCTA-base) !important;
      }
    }
  }
}
.absolute-divider {
  position: absolute;
  left: 0;
  right: 0;
  width: auto;
}
</style>