import i18next from "i18next";

export default {
    methods: {
        isToday(dateString) {
            const inputDate = this.$moment(dateString);
            return this.$moment().isSame(inputDate, 'day');
        },
        isPast(dateString) {
            const today = this.$moment().startOf('day');
            return this.$moment(dateString).isBefore(today);
        },

        hasTimePassed(timeString) {
            const currentTime = this.$moment();
            const deadlineToday = this.$moment(timeString, 'HH:mm');

            return currentTime.isAfter(deadlineToday);
        },
        formatDate(value) {
            const date = this.$moment(value).locale(i18next.language);
            return date.format(this.$t('dayOfWeekAndDayFormat'));
        },
        getNextWorkingDay(date) {
            let nextDay = this.$moment(date, 'YYYY-MM-DD', true).add(1, 'day'); // Start with the next day
            while (nextDay.isoWeekday() > 5) {
                nextDay.add(1, 'day');
            }
            return nextDay;
        },
    },
};
