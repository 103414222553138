<template>
  <div class="d-flex flex-column">
    <absent-header :sickListEntry="sickListEntry" v-if="!prevHasSameMonth"/>

    <div v-if="!prevHasSameMonth" class="sicklist-image mb-4">
      <div class="text-subtitle-1 Text04--text text-capitalize">{{ sickListEntry.start | date('MMMM') }}</div>
      <v-img :eager="true" :src="`/images/calendar/${currentMonth}.svg`"/>
    </div>

    <div class="d-flex flex-row w-100 justify-start my-2">
      <div class="d-flex flex-column align-start day-chips-container">
        <v-chip
            label
            x-large
            class="event-day-chip"
            :class="$vuetify.rtl ? 'ml-sm-7 ml-2' : 'mr-sm-7 mr-2'"
            color="transparent"
        >
          <div class="d-flex flex-column align-center w-100">
            <span class="text-caption Text04--text">{{ sickListEntry.start | date('MMM') }}</span>
            <span class="text-subtitle-1 Text04--text">{{ sickListEntry.start | date('D') }}</span>
          </div>
        </v-chip>
        <div v-if="!isSameDay" class="day-chip-divider flex-grow-1" :class="{'rtl': $vuetify.rtl}"/>
        <v-chip
            v-if="!isSameDay"
            label
            x-large
            class="event-day-chip"
            :class="$vuetify.rtl ? 'ml-2 ml-md-0' : 'mr-2 mr-md-0'"
            color="transparent"
        >
          <div class="d-flex flex-column align-center w-100">
            <span class="text-caption Text04--text">{{ sickListEntry.end | date('MMM') }}</span>
            <span class="text-subtitle-1 Text04--text">{{ sickListEntry.end | date('D') }}</span>
          </div>
        </v-chip>
      </div>

      <v-card color="NewsSkyBlue" elevation="0" class="flex-grow-1" :class="$vuetify.rtl ? 'ml-0' : 'mr-0'">
        <v-app-bar height="50" flat color="transparent">
          <v-toolbar-title>
            <v-flex class="d-flex align-center text--secondary">
              <span class="text-body-2 Text02--text">{{ date }}</span>
            </v-flex>
          </v-toolbar-title>
          <v-spacer/>
          <v-menu v-if="showEdit(sickListEntry) && sickListEntry.user_is_creator" min-width="220" tile offset-y offset-x>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list class="py-0">
              <v-list-item @click="initEditAbsent">
                <v-list-item-icon :class="$vuetify.rtl ? 'ml-3' : 'mr-3'">
                  <v-icon size="25" color="SystemCTA">mdi-pencil</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $t('edit') }}</v-list-item-title>
              </v-list-item>
              <v-divider/>
              <v-list-item v-if="showDeleteInMenu(sickListEntry)" @click="initDeleteConfirm">
                <v-list-item-icon :class="$vuetify.rtl ? 'ml-3' : 'mr-3'">
                  <v-icon size="25" color="AlertRed">mdi-delete</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $t('delete') }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-app-bar>
        <div class="text-body-1 Text01--text px-4 pb-4">
          {{ description }}
        </div>
      </v-card>
      <confirmation-modal
          :max-width="544"
          :loading="submitLoading"
          v-model="deleteConfirmationDialog"
          :title="$t('deleteAbsentMessage')"
          :label="$t('confirmInfo')"
          :apply-button-label="$t('delete')"
          apply-button-color="error"
          @apply="initDeleteAbsentEntry"
      />
    </div>
  </div>
</template>

<script>
import absenceMixin from "@/mixins/absenceMixin";
import ConfirmationModal from "@/components/ConfirmationModal";
import {mapActions} from 'vuex';
import {SickListActions} from "@/store/sicklist";
import i18next from "i18next";
import AbsentHeader from "@/components/pages/AbsentEntriesListPage/components/AbsentHeader";

export default {
  name: 'SickListEntryItem',

  components: {
    ConfirmationModal,
    AbsentHeader,
  },

  mixins: [absenceMixin],

  props: {
    sickListEntry: {
      type: Object,
      required: true
    },
    prevSickListEntry: {
      required: true,
      validator: (value) => typeof value === 'object' || value === undefined
    }
  },
  data() {
    return {
      deleteConfirmationDialog: false,
      submitLoading: false
    }
  },
  methods: {
    ...mapActions({
      deleteAbsentEntry: `sicklist/${SickListActions.DeleteAbsentEntry}`
    }),
    initDeleteConfirm() {
      this.deleteConfirmationDialog = true;
    },
    initDeleteAbsentEntry() {
      this.submitLoading = true;
      this.deleteAbsentEntry({
        memberId: this.$route.params.id,
        absentEntryId: this.sickListEntry.id
      }).then(() => {
        this.$toasted.error(this.$t('absenceDeletedMessage'));
        this.$emit('deleteAbsentEntry');
        this.submitLoading = false;
      }).catch((e) => {
        this.$toasted.error(e.message);
        this.submitLoading = false;
      });
    },
    initEditAbsent() {
      this.$emit('editAbsentEntry', this.sickListEntry);
    }
  },
  computed: {
    currentMonth() {
      return this.$moment(this.sickListEntry.start).locale('en').format('MMMM');
    },
    prevHasSameMonth() {
      if (!this.prevSickListEntry) {
        return false;
      }
      return this.$moment(this.sickListEntry.start).isSame(this.$moment(this.prevSickListEntry.start), 'month');

    },
    isSameDay() {
      return this.$moment(this.sickListEntry.start).isSame(this.$moment(this.sickListEntry.end), 'day');
    },
    date() {
      if (this.sickListEntry.whole_day) {
        return this.$t('absentTxtWholeDay');
      }
      if (this.isSameDay) {
        return this.$moment(this.sickListEntry.start).format(this.$t('normalDateFormat'))
            + ' | '
            + this.$moment(this.sickListEntry.start).format(this.$t('normalTimeFormat'))
            + ' - '
            + this.$moment(this.sickListEntry.end).format(this.$t('normalTimeFormat'));
      }

      return this.$moment(this.sickListEntry.start)
              .format(this.$t('normalDateFormat') + ' ' + this.$t('normalTimeFormat'))
          + ' - '
          + this.$moment(this.sickListEntry.end)
              .format(this.$t('normalDateFormat') + ' ' + this.$t('normalTimeFormat'));
    },
    description() {
      if (this.sickListEntry.reason !== 0) {
        if (this.sickListEntry.reason === 1) {
          return i18next.t('absentTxtHoliday');
        }

        if (this.sickListEntry.reason === 2) {
          return i18next.t('absentTxtOther');
        }
      }

      return this.sickListEntry.description;
    }
  }
}
</script>

<style scoped lang="scss">
.sicklist-image {
  position: relative;

  .text-subtitle-1 {
    position: absolute;
    z-index: 1;
    top: 20px;
    left: 20px;
  }
}
</style>
