<template>
  <div>
    <v-menu tile offset-y left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list class="py-0">
        <v-list-item @click="initMarkAllAsSeenConfirm">
          <v-list-item-icon :class="$vuetify.rtl ? 'ml-3' : 'mr-3'">
            <v-icon size="23" color="SystemCTA">mdi-check</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="Text01--text text-body-2">{{ $t('eventsMarkAllSeen') }}</v-list-item-title>
        </v-list-item>

        <template v-if="showUpdateList">
          <v-divider/>

          <v-list-item @click="updatesDialog = true">
            <v-list-item-icon :class="$vuetify.rtl ? 'ml-3' : 'mr-3'">
              <v-icon size="23" color="SystemCTA">mdi-eye</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="Text01--text text-body-2">{{ $t('eventsShowNewChanged') }}</v-list-item-title>
          </v-list-item>
        </template>

      </v-list>
    </v-menu>

    <confirm-dialog
        :max-width="777"
        v-model="updatesDialog"
        :title="$t('calendarTitle')"
        apply-button-color="primary"
        :apply-button-label="$t('ok')"
        :cancel-button-visible="false"
        @apply="updatesDialog = false"
    >
      <v-row v-if="newEvents.length">
        <v-col cols="12">
          <span class="text-subtitle-1 Text01--text">
            {{ $t('eventsNew') }} ({{ newEvents.length }})
          </span>
        </v-col>
        <v-col v-for="event in newEvents" :key="event.id" cols="12">
          <updated-item :event="event"/>
        </v-col>
      </v-row>
      <v-row v-if="eventsUpdated.length">
        <v-col cols="12">
          <span class="text-subtitle-1 Text01--text">
            {{ $t('eventsFilterUpdated') }} ({{ eventsUpdated.length }})
          </span>
        </v-col>
        <v-col v-for="event in eventsUpdated" :key="event.id" cols="12">
          <updated-item :event="event"/>
        </v-col>
      </v-row>
    </confirm-dialog>

    <confirmation-modal
        :max-width="544"
        v-model="confirmationDialog"
        :title="$t('eventsMarkAllSeenTxt')"
        :label="$t('eventsMarkAllSeenInfo')"
        apply-button-color="primary"
        :apply-button-label="$t('markAll')"
        @apply="initMarkAllAsSeen"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import {EventActions, EventGetters, EventMutations} from "@/store/event";
import ConfirmationModal from "@/components/ConfirmationModal";
import ConfirmDialog from "@/components/ConfirmDialog";
import UpdatedItem from "@/components/pages/EventsListPage/components/UpdatedItem";

export default {
  name: "EventsTopBarMenu",

  components: {
    ConfirmationModal,
    ConfirmDialog,
    UpdatedItem,
  },

  data() {
    return {
      confirmationDialog: false,
      updatesDialog: false,
      filterMenu: false,
      mutableFilters: [],
    }
  },

  mounted() {
    this.rollbackFiltersState()
  },

  methods: {
    ...mapActions({
      markAllAsSeen: `event/${EventActions.MarkAllAsSeen}`,
      fetchEvents: `event/${EventActions.FetchEvents}`,
    }),

    ...mapMutations({
      updateFilters: `event/${EventMutations.SetFilters}`,
    }),

    rollbackFiltersState() {
      this.mutableFilters = this.filters;
    },

    async initMarkAllAsSeen() {
      this.markAllAsSeen()
          .then(() => {
            this.fetchEvents();
          }).catch((e) => {
            this.$toasted.error(e.message);
          })
          .finally(() => {
            this.confirmationDialog = false;
          });
    },

    initMarkAllAsSeenConfirm() {
      this.confirmationDialog = true;
    },
  },

  computed: {
    ...mapGetters({
      filters: `event/${EventGetters.Filters}`,
      showUpdateList: `event/${EventGetters.ShowUpdateList}`,
      lastCalendarUpdate: `event/${EventGetters.LastCalendarUpdate}`,
      events: `event/${EventGetters.Events}`,
    }),

    newEvents() {
      let filteredEvents = [];

      for (const event of this.events) {
        const {events} = event;

        for (const item of events) {
          const {status, modified} = item;

          if (['new'].includes(status) && this.$moment(modified).isAfter(this.$moment(this.lastCalendarUpdate))) {
            filteredEvents.push(item);
          }
        }
      }

      return filteredEvents;
    },

    eventsUpdated() {
      let filteredEvents = [];

      for (const event of this.events) {
        const {events} = event;

        for (const item of events) {
          const {status, modified} = item;

          if (['updated', 'deleted'].includes(status) && this.$moment(modified).isAfter(this.$moment(this.lastCalendarUpdate))) {
            filteredEvents.push(item);
          }
        }
      }

      return filteredEvents;
    },
  }
}
</script>
