<template>
  <v-card
      :style="{ background: eventBgColor }"
      tile
  >
    <div class="d-flex w-100">
      <v-list-item three-line class="w-100" :class="$vuetify.rtl ? 'pr-4 pl-2' : 'pl-4 pr-2'">
        <v-list-item-content>
          <v-list-item-title class="text-subtitle-1 Text01--text mb-1">
            {{ event.title }}
          </v-list-item-title>

          <v-flex class="d-flex align-baseline my-1 w-100">
            <span class="Text02--text text-body-2 text-no-wrap">{{ date }}</span>

            <span
                v-if="event.venue"
                :class="$vuetify.rtl ? 'mr-2' : 'ml-2'"
                class="Text02--text text-body-2 text-truncate"
            >
              | {{ event.venue }}
            </span>
          </v-flex>

        </v-list-item-content>

      </v-list-item>
    </div>
  </v-card>
</template>

<script>
import {bgColorByIndex} from "@/components/pages/EventsListPage/components/EventColors";

export default {
  name: "UpdatedItem",

  props: {
    event: {
      type: Object,
      required: true
    },
  },

  computed: {
    date() {
      if (this.event.allDay) {
        return this.$t('eventAllDay');
      }
      return this.$moment(this.event.start).format(this.$t('normalTimeFormat'))
          + ' - '
          + this.$moment(this.event.end).format(this.$t('normalTimeFormat'));
    },
    eventBgColor() {
      return bgColorByIndex(this.event.colour);
    },
  }
}
</script>

<style scoped/>