import axios from 'axios';
import AuthPayload from "@/models/AuthPayload";
import {ResponseTypes} from "@/store/news";
import {NEWS_PAGE_SIZE} from "@/store/news";
import {NOTICEBOARD_PAGE_SIZE} from "@/store/noticeboard";
import Vue from "vue";
import {ABSENT_ENTRIES_PAGE_SIZE} from "@/store/sicklist";
import {MEMBERS_PAGE_SIZE} from "@/store/member";

const infoApiAxiosInstance = axios.create({ baseURL: process.env.VUE_APP_INFO_API_HOST });

export const LoginsService = {
    async login(email, password, associationCode, keepMeLoggedIn, solution) {
        const authTokens = await AuthPayload.generateRequestAuthTokens();

        const loginPayload = {
            data: {
                Login: {
                    objid: associationCode || '',
                    is_web: true,
                    username: email,
                    password: password,
                    public: authTokens.public,
                    access: authTokens.access,
                    timestamp: authTokens.timestamp,
                    remember_me: keepMeLoggedIn,
                    solution: solution,
                }
            }
        };

        return axios.post('/logins/loginnewv2', loginPayload);
    },
    async rememberMeLogin(rememberMeToken, associationCode) {
        const authTokens = await AuthPayload.generateRequestAuthTokens();
        const rememberMeLoginPayload = {
            data: {
                Login: {
                    objid: associationCode,
                    remember_me_token: rememberMeToken,
                    is_web: true,
                    ...authTokens
                }
            }
        };
        return axios.post('/logins/remembermelogin', rememberMeLoginPayload);
    },
    async rememberMeLogout(rememberMeToken, associationCode) {
        const rememberMeLoginPayload = {
            data: {
                Login: {
                    objid: associationCode,
                    remember_me_token: rememberMeToken,
                }
            }
        };
        return axios.post('/logins/remembermelogout', rememberMeLoginPayload);
    },
    getInvitationTokenInfo(token) {
        return axios.post('/logins/invitationtokeninfo/' + token);
    },
    async getAccountDetails() {
        const authTokens = await AuthPayload.generateRequestAuthTokens();

        return axios.post('/logins/accountdetails/', {
            data: {
                ...authTokens
            }
        });
    },
    async requestAccountDeletion(objid, userid) {
        const authTokens = await AuthPayload.generateRequestAuthTokens();

        return axios.post('/logins/requestdeletion', {
            data: {
                Login: {
                    ...authTokens,
                    objid,
                    userid
                }
            }
        });
    },
    registerUser(entity) {
        const payload = {
            data: {
                first_name: entity.firstName,
                last_name: entity.lastName,
                code: entity.code,
                password: entity.password,
                token: entity.token,
            }
        };

        return axios.post('/logins/webappregistration', payload);
    },
    async forgotPassword(email, associationCode) {
        const clientPassRes = await ClientsService.getClientPass();
        const tokensRes = await ClientsService.getTokens(clientPassRes.data.clientpass);

        const authTokens = AuthPayload.generateApiPayload(tokensRes.data.token1, tokensRes.data.token2);

        return axios.post('/logins/forgotpassnewv2', {
            data: {
                Login: {
                    objid: associationCode || '',
                    username: email,
                    is_web: true,
                    language: 'en',
                    ...authTokens
                }
            }
        });
    },
    async resetPassword(password, token) {
        return axios.post('/logins/resetpassword', {
            data: {
                password: password,
                token: token
            }
        });
    },
    async saveAvatar(objid, id, avatar) {
        const authTokens = await AuthPayload.generateRequestAuthTokens();

        let payload = {
            objid,
            id,
            avatar,
            ...authTokens
        };

        return axios.post('/logins/me/saveavatar', {
            data: {
                Login: payload
            }
        })
    },
    async fetchAvatar(associationId, userId) {
        const authTokens = await AuthPayload.generateRequestAuthTokens();

        return await axios.get('/logins/me/avatar', {
            responseType: "blob",
            headers: {
                'Auth-Access': authTokens.access,
                'Auth-Public': authTokens.public,
                'Auth-Timestamp': authTokens.timestamp,
                'Auth-User': userId,
                'Auth-Association': associationId
            }
        });
    },
    async updateAccountDetails(associationId, userId, firstName, lastName, email, password, currentPassword, appLanguage) {
        const authTokens = await AuthPayload.generateRequestAuthTokens();

        let payload = {
            objid: associationId,
            id: userId,
            first_name: firstName,
            last_name: lastName,
            email: email,
            current_password: currentPassword,
            app_language: appLanguage,
            ...authTokens
        };

        if (password) {
            payload.password = password;
        }

        return axios.post('/logins/editregistrationv2', {
            data: {
                Login: payload
            }
        })
    },
    async getMembers(associationId, userId) {
        const authTokens = await AuthPayload.generateRequestAuthTokens();

        return axios.post('/logins/membersinfo', {
            data: {
                objid: associationId,
                user_id: userId,
                ...authTokens
            }
        });
    }
}

export const NewsService = {
    async getListItems(associationId, userId, {offset = 0, query = '', filter_by_type = 'all', filter_by_receiver_type = 'all', filter_by_statuses = [], filter_by_groups_ids = [], hiddenOnly = false} = {}) {
        const authTokens = await AuthPayload.generateRequestAuthTokens();

        return axios.post('/news/listitems', {
            data: {
                News: {
                    objid: associationId,
                    userid: userId,
                    public: authTokens.public,
                    access: authTokens.access,
                    timestamp: authTokens.timestamp,
                    offset: offset,
                    loadsize: NEWS_PAGE_SIZE,
                    query: query,
                    hidden_only: hiddenOnly,
                    os: '',
                    dpi: '',
                    xdpi: '',
                    density: '',
                    platformWidth: '',
                    logicaldensityfactor: '',
                    override_answered: false,
                    is_web: true,
                    filter_by_type,
                    filter_by_statuses,
                    filter_by_groups_ids,
                    filter_by_receiver_type,
                }
            }
        })
    },

    async getPinnedNews(associationId, userId, {offset = 0} = {}) {
        const authTokens = await AuthPayload.generateRequestAuthTokens();

        return axios.post('/news/listpinneditems', {
            data: {
                News: {
                    objid: associationId,
                    userid: userId,
                    public: authTokens.public,
                    access: authTokens.access,
                    timestamp: authTokens.timestamp,
                    offset: offset,
                    loadsize: NEWS_PAGE_SIZE,
                    is_web: true,
                }
            }
        })
    },

    async getListItem(associationId, userId, id, translationLang) {
        const authTokens = await AuthPayload.generateRequestAuthTokens();

        return axios.post('/news/listitemv2/' + id + (translationLang ? `/${translationLang}` : ''), {
            data: {
                News: {
                    objid: associationId,
                    public: authTokens.public,
                    access: authTokens.access,
                    timestamp: authTokens.timestamp,
                    userid: userId,
                    override_answered: 'false',
                    is_web: true,
                }
            }
        });
    },

    async getLastPinnedNews(associationId, userId) {
        const authTokens = await AuthPayload.generateRequestAuthTokens();

        return axios.post('/news/listlastpinneditems', {
            data: {
                News: {
                    objid: associationId,
                    userid: userId,
                    is_web: true,
                    ...authTokens
                }
            }
        })
    },
    async hideNewsItem(associationId, userId, newsId) {
        const authTokens = await AuthPayload.generateRequestAuthTokens();

        return axios.post('/news/hidenewsitem/', {
            data: {
                News: {
                    objid: associationId,
                    ...authTokens,
                    user_id: userId,
                    news_id: newsId,
                }
            }
        });
    },
    async unHideNewsItem(associationId, userId, newsId) {
        const authTokens = await AuthPayload.generateRequestAuthTokens();

        return axios.post('/news/unhidenewsitem/', {
            data: {
                News: {
                    objid: associationId,
                    ...authTokens,
                    user_id: userId,
                    news_id: newsId,
                }
            }
        });
    },
    async sendResponse(
        associationId,
        userId,
        newsItem,
        {optionsValues, comment, membersIds, signature}
    ) {
        const authTokens = await AuthPayload.generateRequestAuthTokens();

        let feedbackAnswers = [];
        let optionsChosen = [];

        switch (newsItem.response_type) {
            case ResponseTypes.Text:
                for (const [optionId, optionValue] of Object.entries(optionsValues)) {
                    feedbackAnswers.push({
                        option_id: optionId,
                        fbtext: optionValue
                    })
                }
                break;
            case ResponseTypes.Multi:
                optionsChosen = optionsValues;
                break;
            case ResponseTypes.Single:
                optionsChosen = [optionsValues];
                break;
            default:
                throw new Error('Invalid response type');
        }

        return axios.post('/news/saveresponsev2', {
            data: {
                Response: {
                    objid: associationId,
                    user_id: userId,
                    news_id: newsItem._id,
                    responsetype: newsItem.response_type,
                    feedbackanswers: [JSON.stringify(feedbackAnswers)],
                    optionschosen: optionsChosen,
                    comment: comment,
                    members: membersIds,
                    signature: signature ? signature.replace('data:image/jpeg;base64,', '') : null,
                    ...authTokens,
                    userid: userId,
                }
            }
        });
    },
    async toggleFavourite(associationId, newsId) {
        const authTokens = await AuthPayload.generateRequestAuthTokens();

        return axios.post('/news/markasfavouritev2', {
            data: {
                News: {
                    ...authTokens,
                    objid: associationId,
                    newsid: newsId
                }
            }
        });
    },
    async markAllAsRead(associationId) {
        const authTokens = await AuthPayload.generateRequestAuthTokens();

        return axios.post('/news/markallreadv2', {
            data: {
                News: {
                    ...authTokens,
                    objid: associationId,
                }
            }
        });
    },
    async markAsUnread(associationId, newsId) {
        const authTokens = await AuthPayload.generateRequestAuthTokens();

        return axios.post('/news/markasunreadv2', {
            data: {
                News: {
                    ...authTokens,
                    objid: associationId,
                    newsid: newsId
                }
            }
        });
    },
    async markNewsItemAsPinnedUnpinned(objid, newsId, is_pinned) {
        const authTokens = await AuthPayload.generateRequestAuthTokens();

        return axios.post(`/news/${newsId}/markaspinned`, {
            data: {
                News: {
                    ...authTokens,
                    objid,
                    is_pinned
                }
            }
        });
    },

    async unpinAllNewsItems(associationId) {
        const authTokens = await AuthPayload.generateRequestAuthTokens();

        return axios.post('/news/all/markasunpinned', {
            data: {
                News: {
                    ...authTokens,
                    objid: associationId,
                }
            }
        });
    },
    async newsGroups(objid, userid) {
        const authTokens = await AuthPayload.generateRequestAuthTokens();

        return axios.post('/news/groups', {
            data: {
                News: {
                    ...authTokens,
                    objid,
                    userid
                }
            }
        });
    }
}

export const EventsService = {
    async getListItems(associationId, userId, {query = '', filters = []} = {}) {
        const authTokens = await AuthPayload.generateRequestAuthTokens();

        let filtersPayload = {};
        filters.forEach((filter) => {
            filtersPayload[filter] = 'true';
        })

        return axios.post('/events/listitemsv2', {
            data: {
                Event: {
                    objid: associationId,
                    public: authTokens.public,
                    access: authTokens.access,
                    timestamp: authTokens.timestamp,
                    userid: userId,
                    query: query,
                    ...filtersPayload,
                    is_web: true,
                }
            }
        })
    },
    async getListItem(associationId, userId, id, translationLang) {
        const authTokens = await AuthPayload.generateRequestAuthTokens();

        return axios.post('/events/listitemv2/' + id + (translationLang ? `/${translationLang}` : ''), {
            data: {
                Event: {
                    objid: associationId,
                    public: authTokens.public,
                    access: authTokens.access,
                    timestamp: authTokens.timestamp,
                    userid: userId,
                    override_answered: 'false',
                    is_web: true,
                }
            }
        });
    },
    async markAllAsSeen(associationId) {
        const authTokens = await AuthPayload.generateRequestAuthTokens();

        return axios.post('/events/markallseenv2', {
            data: {
                Event: {
                    ...authTokens,
                    objid: associationId,
                }
            }
        })
    },
    async markAsSeen(associationId, eventId) {
        const authTokens = await AuthPayload.generateRequestAuthTokens();

        return axios.post('/events/markasreadv2', {
            data: {
                Event: {
                    ...authTokens,
                    objid: associationId,
                    eventid: eventId
                }
            }
        })
    },
}

export const ClientsService = {
    async getClientPass() {
        const authTokens = await AuthPayload.generateRequestAuthTokens();
        return axios.post('/clients/receivenewv2', {
            data: {
                Client: {
                    objid: "kita",
                    ...authTokens
                }
            }
        }).catch((e) => {
            throw new Error(e.response.data.message);
        });
    },
    async getTokens(clientPass) {
        const authTokens = await AuthPayload.generateRequestAuthTokens();
        return axios.post('/clients/loginnewv2', {
            data: {
                Client: {
                    objid: "kita",
                    password: clientPass,
                    ...authTokens
                }
            }
        }).catch((e) => {
            throw new Error(e.response.data.message);
        });
    },
    async getTokensForRefresh(clientPass) {
        const authTokens = await AuthPayload.generateRequestAuthTokens();

        return axios({
            method: 'post',
            url: '/clients/loginnewv2',
            data: {
                data: {
                    Client: {
                        objid: "kita",
                        password: clientPass,
                        ...authTokens
                    }
                }
            },
            params: {
                withoutUserId: true,
            },
        }).catch((e) => {
            throw new Error(e.response.data.message);
        });
    }
}

export const ContactsService = {
    async getContacts(associationId, userId) {
        const authTokens = await AuthPayload.generateRequestAuthTokens();

        return axios.post('/contacts/listitemsv2', {
            data: {
                Contact: {
                    objid: associationId,
                    userid: userId,
                    public: authTokens.public,
                    access: authTokens.access,
                    timestamp: authTokens.timestamp,
                    language: 'en', // @todo: config
                }
            }
        });
    },
}

export const MembersService = {
    async getMembers(associationId, offset = 0) {
        const authTokens = await AuthPayload.generateRequestAuthTokens();

        return axios.post('/members/listitemsv2', {
            data: {
                Member: {
                    objid: associationId,
                    offset: offset,
                    loadsize: MEMBERS_PAGE_SIZE,
                    ...authTokens
                }
            }
        });
    },
    async getMember(associationId, id) {
        const authTokens = await AuthPayload.generateRequestAuthTokens();

        return axios.post(`/members/listitemv2/${id}`, {
            data: {
                Member: {
                    objid: associationId,
                    ...authTokens
                }
            }
        });
    },
    async updateAvatar(memberId, avatarId) {
        const authTokens = await AuthPayload.generateRequestAuthTokens();

        return axios.post('/members/saveavatarv2', {
            data: {
                Member: {
                    memberid: memberId,
                    avatarid: avatarId,
                    ...authTokens
                }
            }
        });
    },
    async fetchAvatar(memberId, associationId, userId) {
        const authTokens = await AuthPayload.generateRequestAuthTokens();

        return await axios.get(`/members/${memberId}/avatar`, {
            responseType: "blob",
            headers: {
                'Auth-Access': authTokens.access,
                'Auth-Public': authTokens.public,
                'Auth-Timestamp': authTokens.timestamp,
                'Auth-User': userId,
                'Auth-Association': associationId
            }
        });
    },
    async saveAvatar(memberid, objid, userid, avatar) {
        const authTokens = await AuthPayload.generateRequestAuthTokens();

        return axios.post(`/members/${memberid}/saveavatar`, {
            data: {
                Member: {
                    objid,
                    userid,
                    avatar,
                    ...authTokens
                }
            }
        });
    },
    async deleteAvatar(memberid, objid, userid) {
        const authTokens = await AuthPayload.generateRequestAuthTokens();

        return axios.post(`/members/${memberid}/deleteavatar`, {
            data: {
                Member: {
                    objid,
                    userid,
                    ...authTokens
                }
            }
        }, {
            headers: {
                'Auth-Access': authTokens.access,
                'Auth-Public': authTokens.public,
                'Auth-Timestamp': authTokens.timestamp,
                'Auth-User': userid,
                'Auth-Association': objid
            }
        });
    }
}

export const FoodPlansService = {
    async getFoodPlans(associationId, userId, offset) {
        const authTokens = await AuthPayload.generateRequestAuthTokens();

        return axios.post('/foodplans/listitemsv2', {
            data: {
                FoodPlan: {
                    objid: associationId,
                    userid: userId,
                    offset: offset, // @todo: config
                    loadsize: 20, // @todo: config
                    public: authTokens.public,
                    access: authTokens.access,
                    timestamp: authTokens.timestamp,
                    language: 'en', // @todo: config
                }
            }
        });
    },

    async getFoodPlanPdf(associationId, userId, foodPlanId) {
        const authTokens = await AuthPayload.generateRequestAuthTokens();

        return axios.post('/foodplans/showpdfv2', {
            data: {
                FoodPlan: {
                    id: foodPlanId,
                    objid: associationId,
                    userid: userId,
                    public: authTokens.public,
                    access: authTokens.access,
                    timestamp: authTokens.timestamp,
                    language: 'en', // @todo: config
                }
            }
        }, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            }
        });
    },
}
export const TimetablesService = {
    async getTimetables(associationId, userId, offset) {
        const authTokens = await AuthPayload.generateRequestAuthTokens();

        return axios.post('/timetables/listitemsv2', {
            data: {
                Timetable: {
                    objid: associationId,
                    userid: userId,
                    offset: offset, // @todo: config
                    loadsize: 20, // @todo: config
                    public: authTokens.public,
                    access: authTokens.access,
                    timestamp: authTokens.timestamp,
                    language: 'en', // @todo: config
                }
            }
        });
    },

    async getTimetablePdf(associationId, userId, foodPlanId) {
        const authTokens = await AuthPayload.generateRequestAuthTokens();

        return axios.post('/timetables/showpdfv2', {
            data: {
                Timetable: {
                    id: foodPlanId,
                    objid: associationId,
                    userid: userId,
                    public: authTokens.public,
                    access: authTokens.access,
                    timestamp: authTokens.timestamp,
                    language: 'en', // @todo: config
                }
            }
        }, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            }
        });
    },
}

export const HourPlansService = {
    async getHourPlans(associationId, userId, offset) {
        const authTokens = await AuthPayload.generateRequestAuthTokens();

        return axios.post('/hourplans/listitemsv2', {
            data: {
                HourPlan: {
                    objid: associationId,
                    userid: userId,
                    offset: offset, // @todo: config
                    loadsize: 20, // @todo: config
                    public: authTokens.public,
                    access: authTokens.access,
                    timestamp: authTokens.timestamp,
                    language: 'en', // @todo: config
                }
            }
        });
    },

    async getHourPlanPdf(associationId, userId, hourPlanId) {
        const authTokens = await AuthPayload.generateRequestAuthTokens();

        return axios.post('/hourplans/showpdfv2', {
            data: {
                HourPlan: {
                    id: hourPlanId,
                    objid: associationId,
                    userid: userId,
                    public: authTokens.public,
                    access: authTokens.access,
                    timestamp: authTokens.timestamp,
                    language: 'en', // @todo: config
                }
            }
        }, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            }
        });
    },
}

export const SickListService = {
    async saveAbsentEntry(memberId, {entireDay, start, end, comment, reason, cancelLunches, mode, id}) {
        const authTokens = await AuthPayload.generateRequestAuthTokens();

        return axios.post('/sicklist/savesickentryv2', {
            data: {
                Sicklist: {
                    member_id: memberId,
                    wholeday: entireDay ? 'true' : 'false',
                    start: Vue.prototype.$moment(start).locale('en').format('YYYY-MM-DD HH:mm:ss'),
                    end: Vue.prototype.$moment(end).locale('en').format('YYYY-MM-DD HH:mm:ss'),
                    comments: comment,
                    reason: reason,
                    cancel_lunches: cancelLunches,
                    mode: mode,
                    id: id,
                    ...authTokens
                }
            }
        });
    },
    async getListItems(memberid, offset = 0, filter_by_year, filter_by_month) {
        const authTokens = await AuthPayload.generateRequestAuthTokens();

        return axios.post('/sicklist/listitemsv2', {
            data: {
                Sicklist: {
                    filter_by_year,
                    filter_by_month,
                    offset,
                    loadsize: ABSENT_ENTRIES_PAGE_SIZE,
                    memberid,
                    ...authTokens
                }
            }
        });
    },
    async deleteSickListEntry(memberId, absentEntryId) {
        const authTokens = await AuthPayload.generateRequestAuthTokens();

        return axios.post('/sicklist/deletesickentryv2', {
            data: {
                Sicklist: {
                    id: absentEntryId,
                    member_id: memberId,
                    ...authTokens
                }
            }
        });
    }
}

export const LegalService = {
    async getListOfLegalDocuments(associationId) {
        return infoApiAxiosInstance.get(`/api/associations/${associationId}/legal-documents`);
    },

    async getPdf(associationId, type) {
        return infoApiAxiosInstance.get(`/api/associations/${associationId}/legal-documents/${type}`, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            }
        });
    },

    async getLegalLinks() {
        return infoApiAxiosInstance.get('/api/legal-links');
    }
}

export const AssociationsService = {
    async changeAssociation(associationId, userId, newAssociationId) {
        const authTokens = await AuthPayload.generateRequestAuthTokens();

        return axios.post('/associations/changeassociationv2', {
            data: {
                Association: {
                    objid: associationId,
                    newObjid: newAssociationId,
                    userid: userId,
                    public: authTokens.public,
                    access: authTokens.access,
                    timestamp: authTokens.timestamp,
                    is_web: true,
                }
            }
        });
    },

    async getListOfAssociations(associationId, userId) {
        const authTokens = await AuthPayload.generateRequestAuthTokens();

        return axios.post('/associations/getlistofassociationsv2', {
            data: {
                Association: {
                    objid: associationId,
                    userid: userId,

                    public: authTokens.public,
                    access: authTokens.access,
                    timestamp: authTokens.timestamp,

                    is_web: true,
                }
            }
        });
    }
}

export const NoticeboardService = {
    async getNoticeboardItems(associationId, userId, offset = 0) {
        const authTokens = await AuthPayload.generateRequestAuthTokens();

        return axios.post('/noticeboard/listitemsv2', {
            data: {
                Noticeboard: {
                    objid: associationId,
                    userid: userId,
                    offset: offset,
                    loadsize: NOTICEBOARD_PAGE_SIZE,
                    public: authTokens.public,
                    access: authTokens.access,
                    timestamp: authTokens.timestamp,
                }
            }
        });
    },
    async getNoticeboardFile(associationId, userId, id, acceptType) {
        const authTokens = await AuthPayload.generateRequestAuthTokens();

        return axios.post('/noticeboard/showfilev2', {
            data: {
                Noticeboard: {
                    id: id,
                    objid: associationId,
                    userid: userId,
                    public: authTokens.public,
                    access: authTokens.access,
                    timestamp: authTokens.timestamp,
                    language: 'en',
                }
            }
        }, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': acceptType
            }
        })
    },
}