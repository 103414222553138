
export function generateAppleCalendarIcsContent(moment, event) {
    const separator = (navigator.appVersion.indexOf('Win') !== -1) ? '\r\n' : '\n';

    return [
        'BEGIN:VCALENDAR',
        'VERSION:2.0',
        'PRODID:-//hacksw/handcal//NONSGML v1.0//EN',
        'BEGIN:VEVENT',
        'DTSTART:' + moment(event.start).format('YYYYMMDD[T]HHmmss'),
        'DTEND:' + moment(event.end).format('YYYYMMDD[T]HHmmss'),
        'SUMMARY:' + event.title,
        'DESCRIPTION:' + event.content,
        'END:VEVENT',
        'END:VCALENDAR'
    ].join(separator)
}

export function generateGoogleCalendarLink(moment, event) {
    const dateFormat = event.allDay ? 'YYYYMMDD' : 'YYYYMMDD[T]HHmmss';
    const payload = [
        'text=' + encodeURIComponent(event.title),
        'dates='
            + moment(event.start).format(dateFormat)
            +'/'
            + moment(event.end).format(dateFormat),
        'details=' + encodeURIComponent(event.content),
    ].join('&');

    return 'https://www.google.com/calendar/render?action=TEMPLATE&'  +  payload  + '&sf=true&output=xml';
}