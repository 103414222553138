<template>
  <v-row class="h-100">
    <v-col>
      <div class="main-content-container wide h-100">
        <v-row sticky-container no-gutters class="justify-center h-100 align-content-start align-content-md-stretch">
          <TabsList/>

          <v-col cols="12" md="8" sticky-container>

            <v-list two-line class="py-0 mt-15 mt-md-0" v-if="isListView">
              <SkeletonLoader v-if="loading"/>

              <v-card tile elevation="0" v-else>
                <v-card-text class="pa-0">
                  <sick-list-entry-item
                      v-for="(sickListEntry, index) in sickListEntries"
                      :key="sickListEntry.id"
                      :sick-list-entry="sickListEntry"
                      :prev-sick-list-entry="sickListEntries[index - 1]"
                      @editAbsentEntry="editAbsentEntry"
                      @deleteAbsentEntry="deleteAbsentEntry"
                  />
                  <infinite-loading :identifier="infiniteId" @infinite="loadSickListEntries">
                    <template v-slot:spinner>
                      <SkeletonLoader/>
                    </template>
                    <template v-slot:no-results>
                      <empty-state
                          class="pt-0 pb-0"
                          :title="$t('no_absent_entries_title')"
                          :subtitle="$t('no_absent_entries_subtitle')"
                          image="/images/empty-absent-entries.svg"
                      />
                    </template>
                  </infinite-loading>

                </v-card-text>
              </v-card>
            </v-list>

            <absent-calendar v-else @editAbsentEntry="editAbsentEntry" />
          </v-col>
        </v-row>
      </div>

      <absent-entry-dialog
          v-model="absentEntryForm"
          :mode="absentEntryFormMode"
          :sick-list-entry="sickListEntry"
          :config="config"
          @saveAbsentEntry="saveAbsentEntry"
          @deleteAbsence="initDeleteConfirm"
      />
      <confirmation-modal
          :max-width="544"
          :loading="submitLoading"
          v-model="deleteConfirmationDialog"
          :title="$t('deleteAbsentMessage')"
          :label="$t('confirmInfo')"
          :apply-button-label="$t('delete')"
          apply-button-color="error"
          @apply="initDeleteAbsentEntry"
      />
      <v-btn
          style="bottom: 70px"
          fab
          fixed
          bottom
          right
          color="primary"
          @click="openAbsentEntryForm"
      >
        <v-icon size="35">mdi-plus</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
import AbsentEntryDialog from "@/components/pages/AbsentEntriesListPage/components/AbsentEntryDialog";
import {mapActions, mapGetters} from 'vuex';
import {MemberGetters, MemberActions} from "@/store/member";
import {SickListActions, SickListFormModes} from "@/store/sicklist";
import SickListEntryItem from "@/components/pages/AbsentEntriesListPage/components/SickListEntryItem";
import AbsentCalendar from "@/components/pages/AbsentEntriesListPage/components/AbsentCalendar";
import EmptyState from "@/components/EmptyState";
import TabsList from "@/components/pages/MemberPage/components/TabsList";
import EventBus from '@/services/EventBus';
import ConfirmationModal from "@/components/ConfirmationModal.vue";

export default {
  name: "AbsentEntriesListPage",

  components: {
    ConfirmationModal,
    AbsentCalendar,
    SickListEntryItem,
    AbsentEntryDialog,
    EmptyState,
    TabsList,
  },
  data() {
    return {
      loading: true,
      absentEntryForm: false,
      absentEntryFormMode: null,
      offset: 0,
      infiniteId: +new Date(),
      sickListEntries: [],
      sickListEntry: null,
      config: null,
      isListView: true,
      deleteConfirmationDialog: false,
      submitLoading: false,
    }
  },

  computed: {
    ...mapGetters({
      member: `member/${MemberGetters.Member}`,
    }),
  },

  async mounted() {
    await this.fetchMember(this.$route.params.id);

    const {config: {sicklist}} = this.member;

    if (!sicklist) {
      return this.$router.push('/404');
    }

    this.loading = false;

    EventBus.$on('isListView', (value) => {
      this.isListView = value;

      this.resetList();
    });
  },

  methods: {
    ...mapActions({
      fetchMember: `member/${MemberActions.FetchMember}`,
      fetchSickListEntries: `sicklist/${SickListActions.FetchSickListEntries}`,
      deleteAbsentEntryAction: `sicklist/${SickListActions.DeleteAbsentEntry}`
    }),

    openAbsentEntryForm() {
      this.absentEntryFormMode = SickListFormModes.Add;
      this.sickListEntry = null;
      this.absentEntryForm = true;
    },

    loadSickListEntries($state) {
      this.fetchSickListEntries({
        memberId: this.member.id,
        offset: this.offset
      }).then(({items, config}) => {
        this.config = config;

        if (items.length > 0) {
          this.offset++;
          this.sickListEntries.push(...items);
          $state.loaded();
        } else {
          $state.complete();
        }
      });
    },

    resetList() {
      this.sickListEntries.id = null;
      this.sickListEntries = [];
      this.offset = 0;
      this.infiniteId += 1;
    },

    saveAbsentEntry() {
      this.resetList();
    },

    editAbsentEntry(sickListEntry) {
      this.absentEntryFormMode = SickListFormModes.Edit;
      this.sickListEntry = sickListEntry;
      this.absentEntryForm = true;
    },

    deleteAbsentEntry() {
      this.resetList();
    },
    initDeleteConfirm() {
      this.deleteConfirmationDialog = true;
    },
    initDeleteAbsentEntry() {
      this.submitLoading = true;
      this.deleteAbsentEntryAction({
        memberId: this.$route.params.id,
        absentEntryId: this.sickListEntry.id
      }).then(() => {
        this.$toasted.error(this.$t('absenceDeletedMessage'));
        this.deleteAbsentEntry();
        this.submitLoading = false;
        this.deleteConfirmationDialog = false;
        this.absentEntryForm = false;
        this.resetList();
      }).catch((e) => {
        this.$toasted.error(e.message);
        this.submitLoading = false;
        this.absentEntryForm = false;
        this.deleteConfirmationDialog = false;
        this.resetList();
      });
    },
  }
}
</script>

<style scoped>
.v-card__text > div:first-child ::v-deep .v-banner {
  visibility: visible;
  margin-bottom: 24px;
}
</style>