<template>
  <v-row>
    <v-col>
      <div class="main-content-container tight">
        <v-col cols="12" no-gutters v-for="noticeboardItem in noticeboardItems" :key="noticeboardItem.id">
          <v-card :loading="requestedNoticeboardId === noticeboardItem.id" tile outlined>
            <v-card-text class="pa-0">
              <v-list-item @click="openPdfOrImage(noticeboardItem)" v-ripple="{ class: 'SystemCTA--text' }">
                <v-list-item-icon :class="$vuetify.rtl ? 'ml-4' : 'mr-4'">
                  <v-img v-if="noticeboardItem.type === 'pdf'"
                         src="/images/pdf.svg"/>
                  <v-img v-if="noticeboardItem.type === 'png'"
                         src="/images/png.svg"/>
                  <v-img v-if="['jpeg', 'jpg'].includes(noticeboardItem.type)"
                         src="/images/jpg.svg"/>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-subtitle-1 Text01--text text-wrap">
                    {{ noticeboardItem.title }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-body-1 Text02--text">
                    {{ noticeboardItem.created_at | unixToDate($moment, $t('normalDateFormat')) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card-text>
          </v-card>
        </v-col>

        <infinite-loading :identifier="infiniteId" @infinite="initFetchNoticeboardItems">
          <template v-slot:spinner>
            <SkeletonLoader/>
          </template>
          <template v-slot:no-results>
            <empty-state
                :title="$t('no_noticeboard_title')"
                image="/images/empty-noticeboards.svg"
            />
          </template>
        </infinite-loading>

        <image-viewer-dialog
            v-model="imageDialog"
            :title="imageDialogTitle"
            :imageData="imageData"
            :imageType="imageAcceptType"
            @close="requestedNoticeboardId = null"
        />

        <pdf-viewer-dialog
            v-model="pdfDialog"
            :title="pdfDialogTitle"
            :pdfData="pdfData"
            @close="requestedNoticeboardId = null"
        />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import {mapActions} from "vuex";
import {NoticeboardActions} from "@/store/noticeboard";
import PdfViewerDialog from "@/components/PdfViewerDialog";
import ImageViewerDialog from "@/components/ImageViewerDialog";
import EmptyState from "@/components/EmptyState";

export default {
  name: "NoticeboardListPage",

  components: {
    PdfViewerDialog,
    ImageViewerDialog,
    EmptyState
  },

  data() {
    return {
      loading: true,
      offset: 0,
      infiniteId: +new Date(),
      noticeboardItems: [],

      pdfDialog: false,
      confirmationDialog: false,
      pdfDialogTitle: null,
      pdfData: null,

      imageDialog: false,
      imageConfirmationDialog: false,
      imageDialogTitle: null,
      imageData: null,
      imageAcceptType: null,
      requestedNoticeboardId: null,
    }
  },

  filters: {
    unixToDate(value, moment, format) {
      return value ? moment.unix(value).format(format) : null;
    }
  },

  methods: {
    ...mapActions({
      fetchNoticeboardItems: `noticeboard/${NoticeboardActions.fetchNoticeboardItems}`,
      getNoticeboardFile: `noticeboard/${NoticeboardActions.getNoticeboardFile}`,
    }),

    initFetchNoticeboardItems($state) {
      this.fetchNoticeboardItems(this.offset)
          .then((data) => {
            if (data.length > 0) {
              this.offset++;
              this.noticeboardItems.push(...data);
              $state.loaded();
            } else {
              $state.complete();
            }
          });
    },
    async initGetNoticeboardPdfFile(noticeboardId, noticeboardTitle) {
      await this.getNoticeboardFile(noticeboardId)
          .then((data) => {
            this.pdfData = data;
            this.pdfDialogTitle = noticeboardTitle;
            this.pdfDialog = true;
            this.requestedNoticeboardId = null;
          })
          .catch((e) => {
            this.$toasted.error(e.message);
            this.requestedNoticeboardId = null;
          });
    },
    async initGetNoticeboardImageFile(noticeboardId, noticeboardTitle, acceptType) {
      await this.getNoticeboardFile(noticeboardId, acceptType)
          .then((data) => {
            this.imageData = data;
            this.imageDialogTitle = noticeboardTitle;
            this.imageDialog = true;
            this.imageAcceptType = acceptType;
            this.requestedNoticeboardId = null;
          })
          .catch((e) => {
            this.$toasted.error(e.message);
            this.requestedNoticeboardId = null;
          });
    },
    openPdfOrImage(noticeboardItem) {
      const acceptType = this.getFileAcceptType(noticeboardItem.type);

      this.requestedNoticeboardId = noticeboardItem.id;

      if (noticeboardItem.type === 'pdf') {
        this.initGetNoticeboardPdfFile(noticeboardItem.id, noticeboardItem.title, acceptType);
        return;
      }

      this.initGetNoticeboardImageFile(noticeboardItem.id, noticeboardItem.title, acceptType);
    },
    getFileAcceptType(type) {
      switch (type) {
        case 'pdf':
          return 'application/pdf';
        case 'png':
          return 'image/png';
        case 'jpeg':
          return 'image/jpeg';
        case 'jpg':
          return 'image/jpeg';
      }
    }
  }
}
</script>
