<template>
  <div class="event-day-picker w-100">
    <v-toolbar dense flat class="mb-8 d-none d-md-block">
      <v-toolbar-title class="text-subtitle-1 Text01--text">
        {{ selectedDay.format('MMMM YYYY') }}
      </v-toolbar-title>

      <v-spacer/>

      <v-btn :class="$vuetify.rtl ? 'ml-1' : 'mr-1'" color="SystemCTA" icon @click="toPrev()">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn :class="$vuetify.rtl ? 'mr-1' : 'ml-1'" color="SystemCTA" icon @click="toNext()">
        <v-icon color="primary">mdi-chevron-right</v-icon>
      </v-btn>
    </v-toolbar>

    <v-toolbar dense flat class="mb-5 d-block d-md-none">
      <v-btn :class="$vuetify.rtl ? 'ml-1' : 'mr-1'" color="SystemCTA" icon @click="toPrev()">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>

      <v-toolbar-title class="text-subtitle-1 Text01--text">
        {{ selectedDay.format('MMMM YYYY') }}
      </v-toolbar-title>

      <v-btn :class="$vuetify.rtl ? 'mr-2' : 'ml-2'" small color="SystemCTA" icon v-if="renderType === 'week'"
             @click="setRenderType('month')">
        <v-icon>mdi-menu-down</v-icon>
      </v-btn>

      <v-btn :class="$vuetify.rtl ? 'mr-2' : 'ml-2'" small color="SystemCTA" icon v-else
             @click="setRenderType('week')">
        <v-icon>mdi-menu-up</v-icon>
      </v-btn>

      <v-btn :class="$vuetify.rtl ? 'mr-1' : 'ml-1'" color="SystemCTA" icon @click="toNext()">
        <v-icon color="primary">mdi-chevron-right</v-icon>
      </v-btn>
    </v-toolbar>

    <div class="d-flex flex-row align-center justify-space-between px-2">

      <div class="w-100">
        <div class="d-flex flex-row mb-4" key="weekDaysTitles">
          <v-flex class="d-flex flex-basis-0 justify-center text-subtitle-2 Text01--text"
                  v-for="(weekDay, i) in weekDays" :key="'weekTitle_' + i">
            {{ weekDay }}
          </v-flex>
        </div>
        <div class="d-flex flex-row mb-2" v-for="(week, i) in days" :key="getWeekKey(week, i)">
          <v-flex class="d-flex flex-basis-0 justify-center" v-for="(day, j) in week"
                  :key="getWeekKey(week, i) + '_' + j">
            <div class="d-flex flex-column" v-if="day">
              <v-btn
                  depressed
                  class="event-day-picker-button v-btn--round"
                  :color="isToday(day) && !isSelectedDay(day) ? 'IconsLightBlue' : 'primary'"
                  :text="!isSelectedDay(day) && !isToday(day)"
                  @click="setSelectedDay(day)"
              >
                <div class="text-body-1" :class="{'Text01--text': !isSelectedDay(day)}">
                  {{ day.format('D') }}
                </div>
              </v-btn>
              <div class="d-flex flex-row justify-center mt-2">
                <div
                    v-if="hasEvents(day)"
                    :key="getWeekKey(week, i) + '_' + j"
                    :style="{background: 'var(--v-SystemCTA-base)'}"
                    class="event-dot"
                />
              </div>
            </div>
          </v-flex>
        </div>
      </div>

    </div>

  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: Object,
    },
    events: {
      type: Array
    }
  },

  model: {
    prop: 'value',
    event: 'update',
  },

  data() {
    return {
      renderType: 'month',
    }
  },

  mounted() {
    if (this.$vuetify.breakpoint.smAndDown) {
      this.renderType = 'week';
    }
  },

  computed: {
    selectedDay: {
      get: function () {
        return this.value;
      },
      set: function (newValue) {
        this.$emit('update', newValue.clone());
      }
    },

    weekDays() {
      return [this.$t('mon'), this.$t('tue'), this.$t('wed'), this.$t('thu'), this.$t('fri'), this.$t('sat'), this.$t('sun')];
    },

    days() {
      let days = [];

      if (this.renderType === 'week') {
        let day = this.$moment(this.selectedDay).startOf('isoWeek');
        let week = [];
        for (let i = 0; i < 7; i++) {
          week.push(day.clone());
          day.add(1, 'day');
        }
        days.push(week);

        return days;
      }

      let day = this.selectedDay.clone().startOf('month');
      let selectedMonth = day.month();
      while (selectedMonth === day.month()) {
        let preFilledCells = day.isoWeekday() - 1;
        let week = new Array(preFilledCells);
        week.fill(null);
        let weekNumber = day.isoWeek();
        while (weekNumber === day.isoWeek() && selectedMonth === day.month()) {
          week.push(day.clone());
          day.add(1, 'day');
        }
        if (selectedMonth !== day.month()) {
          let extraCells = new Array(7 - week.length);
          extraCells.fill(null);
          week.push(...extraCells);
        }
        days.push(week);
      }


      return days;
    }
  },

  methods: {
    isToday(day) {
      return day.isSame(this.$moment(), 'day');
    },
    isSelectedDay(day) {
      return day.isSame(this.selectedDay, 'day');
    },
    setSelectedDay(day) {
      this.selectedDay = day.clone();
    },
    setRenderType(type) {
      this.renderType = type;
    },
    getWeekKey(week, index) {
      for (let i = 0; i < week.length; i++) {
        if (week[i]) {
          return week[i].isoWeek();
        }
      }
      return index;
    },
    toPrev() {
      const accuracy = this.renderType === 'month' ? 'month' : 'isoWeek';
      this.selectedDay = this.selectedDay.clone().subtract(1, accuracy);
    },
    toNext() {
      const accuracy = this.renderType === 'month' ? 'month' : 'isoWeek';
      this.selectedDay = this.selectedDay.clone().add(1, accuracy);
    },
    hasEvents(requestedDay) {
      if (!this.events) {
        return false;
      }

      let day = this.events.find((day) => {
        const date = this.$moment(day.date);

        return date.date() === requestedDay.date()
            && date.month() === requestedDay.month()
            && date.year() === requestedDay.year();
      });

      return Boolean(day);
    },
  }
}
</script>
