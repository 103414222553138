import { mapGetters } from "vuex";
import { UserGetters } from "@/store/user";

export default {
    computed: {
        ...mapGetters({
            accountConfiguration: `user/${UserGetters.AccountConfiguration}`,
        }),

        pickupTimeIsEditable() {
            return this.accountConfiguration.pickupTimeIsEditableByAppUsers;
        },

        lunchIsEditable() {
            return this.accountConfiguration.lunchIsEditableByAppUsers;
        },

        lunchEditMode() {
            return this.accountConfiguration.lunchEditMode;
        }
    },

    methods: {
        formatPickupTime(value) {
            if (!value) {
                return '';
            }
            return this.$moment(value, 'HH:mm').format(this.$t('normalTimeFormat'));
        },
        formatLunchRequired(lunchRequired) {
            return lunchRequired ? this.$t('yes') : this.$t('no');
        },

        getActualLunch(detail) {
            return detail.changed_lunch_required !== null ? detail.changed_lunch_required : detail.standard_lunch_required;
        },

        getActualPickup(detail) {
            return detail.changed_pickup ? detail.changed_pickup_time : detail.standard_pickup_time;
        },

        getAbsentTextForList(absence, today) {
            const startDate = this.$moment(absence.start);
            const endDate = this.$moment(absence.end);
            const wholeDay = absence.whole_day;
            const todayDate = this.$moment(today);

            if (startDate.isSame(endDate, 'day')) {
                if (wholeDay) {
                    return this.$t('eventAllDay');
                } else {
                    return this.$t('memberAbsentTime', {
                        start: startDate.format(this.$t('normalTimeFormat')),
                        end: endDate.format(this.$t('normalTimeFormat'))
                    });
                }
            } else {
                if (wholeDay) {
                    return this.$t('eventAllDay');
                } else if (startDate.isSame(todayDate, 'day')) {
                    return `${this.$t('from')} ${startDate.format(this.$t('normalTimeFormat'))}`;
                } else if (endDate.isSame(todayDate, 'day')) {
                    return `${this.$t('to')} ${endDate.format(this.$t('normalTimeFormat'))}`
                } else {
                    return this.$t('eventAllDay');
                }
            }
        },

        getAbsentTextForCard(absence) {
            const startDate = this.$moment(absence.start);
            const endDate = this.$moment(absence.end);
            const wholeDay = absence.whole_day;

            if (startDate.isSame(endDate, 'day')) {
                if (wholeDay) {
                    return this.$t('eventAllDay');
                } else {
                    return this.$t('memberAbsentTime', {
                        start: startDate.format(this.$t('normalTimeFormat')),
                        end: endDate.format(this.$t('normalTimeFormat'))
                    });
                }
            } else {
                if (wholeDay) {
                    return this.$t('memberAbsentTime', {
                        start: startDate.format(this.$t('normalDateFormat')),
                        end: endDate.format(this.$t('normalDateFormat'))
                    });
                } else {
                    return this.$t('memberAbsentTime', {
                        start: `${startDate.format(this.$t('normalDateFormat'))} ${startDate.format(this.$t('normalTimeFormat'))}`,
                        end: `${endDate.format(this.$t('normalDateFormat'))} ${endDate.format(this.$t('normalTimeFormat'))}`
                    });
                }
            }
        },

        isWholeDayAbsence(detail) {
            if (detail.absences.length === 0) {
                return false;
            }
            let first = detail.absences.find(Boolean)
            return this.isAbsentWholeDay(first, detail.date);
        },

        isAbsentWholeDay(absence, today) {
            const startDate = this.$moment(absence.start);
            const endDate = this.$moment(absence.end);
            const wholeDay = absence.whole_day;
            const todayDate = this.$moment(today);

            if (wholeDay) {
                return true;
            }
            if (!startDate.isSame(endDate, 'day')) {
                return !(startDate.isSame(todayDate, 'day') || endDate.isSame(todayDate, 'day'));
            }
            return false;
        },
    },
};
