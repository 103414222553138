import Vue from "vue";
import i18next from "i18next";
import {BCP_47_LANGUAGE_TAGS, RTL_LANGUAGE_CODES, simpleLocaleName} from "../i18nInit";
import {AppConfig} from "@/helpers/AppConfig";
import {localize} from 'vee-validate';

export function getBrowserLocale(options = {}) {
    const defaultOptions = {countryCodeOnly: false}

    const opt = {...defaultOptions, ...options}

    const navigatorLocale =
        navigator.languages !== undefined
            ? navigator.languages[0]
            : navigator.language

    if (!navigatorLocale) {
        return undefined
    }

    return opt.countryCodeOnly
        ? navigatorLocale.trim().split(/-|_/)[0]
        : navigatorLocale.trim()
}

export function saveLocale(locale) {
    const expires = process.env.VUE_APP_REMEMBER_ME_LIFETIME_DAYS || 90;
    Vue.cookie.set('locale', locale, {
        expires: expires + 'D',
        secure: AppConfig.productionMode,
        samesite: AppConfig.sameSiteCookieConfig
    });
}

export function setVeeValidateTranslations(locale) {
    import(`vee-validate/dist/locale/${locale}.json`).then(file => {
        localize(locale, file);
    });
}

export function getInitialLocale() {
    const prevLocale = simpleLocaleName(Vue.cookie.get('locale'));
    if (prevLocale) {
        return prevLocale;
    }

    let locale = i18next.options.fallbackLng.find(Boolean);

    saveLocale(locale);
    Vue.prototype.$moment.locale(locale);

    setVeeValidateTranslations(locale);

    return locale;
}

export function isInAppTranslationRequestedLangRTL(lang) {
    return RTL_LANGUAGE_CODES.includes(lang);
}

export function getBCP47LanguageTag(langCode) {
    if (Object.hasOwn(BCP_47_LANGUAGE_TAGS, langCode)) {
        return BCP_47_LANGUAGE_TAGS[langCode];
    }

    return langCode;
}

export function downloadBlob(content, mimeType, fileName) {
    const blob = new Blob([content], {type: mimeType});
    if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, fileName);
    } else {
        let link = document.createElement('a');
        if (link.download !== undefined) {
            let url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}

export function defaultApiPromiseHandler(data) {
    if (data.success && data.success === '1') {
        return data;
    } else {
        throw new Error(data.message);
    }
}

export function isImageSizeLargerLimit(size) {
    return size > (AppConfig.imageSizeLimit * 1048576);
}