import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex';
import VueCookie from 'vue-cookie';
import Vuelidate from 'vuelidate';
import Toasted from 'vue-toasted';
import i18next from 'i18next';
import '@fortawesome/fontawesome-free/css/all.css'
import Clipboard from 'v-clipboard';
import VueI18Next from '@panter/vue-i18next';
import VueSignaturePad from 'vue-signature-pad';
import InfiniteLoading from 'vue-infinite-loading';
import vuetify from './plugins/vuetify';
import MomentPlugin from "./plugins/moment-timezone";
import router from './routes/index';
import store from './store/index';
import i18nInit from './i18nInit';
import globalFiltersInit from './globalFiltersInit';
import interceptorsInit from './interceptors';
import StayInformedChat from 'stayinformed-chat-app/dist/stayinformed-chat.umd';
import eventBus from './services/EventBus';
import '@/styles/app.scss';
import AuthPayload from './models/AuthPayload';
import addChatTranslations from "@/helpers/addChatTranslations";
import pdf from 'vue-pdf';
import Sticky from 'vue-sticky-directive';
import SkeletonLoader from "@/components/SkeletonLoader"

import '@/plugins/vee-validate';

Vue.component("SkeletonLoader", SkeletonLoader);

Vue.config.productionTip = false;

Vue.use(Sticky);
Vue.use(Vuex);
Vue.use(VueCookie);
Vue.use(Vuelidate);
Vue.use(MomentPlugin);
Vue.use(VueSignaturePad);
Vue.use(Clipboard)
Vue.use(Toasted, {
    position: 'top-center',
    iconPack: 'mdi',
    icon: ' ',
    duration: 15000,
    className: 'info-app-toasted',
    action: {
        icon: 'mdi-close',
        onClick: (e, toast) => {
            toast.goAway(0);
        }
    }
});

Vue.use(InfiniteLoading, {
    slots: {
        noMore: ''
    }
});

Vue.use(VueI18Next);
i18nInit();
const i18n = new VueI18Next(i18next);

// should go after i18next init
addChatTranslations();

const authPayload = new AuthPayload();
Vue.use(StayInformedChat, {
    router,
    store,
    authPayload,
    baseUrl: process.env.VUE_APP_CHAT_API_HOST,
    socketIOConnectionStr: process.env.VUE_APP_SOCKET_IO_CONNECTION_STR,
    eventBus,
    chatTarget: process.env.VUE_APP_CHAT_TARGET,
    pdf
});

globalFiltersInit();
interceptorsInit();

new Vue({
    vuetify,
    i18n,
    router,
    store,
    render: h => h(App),
}).$mount('#app')
